import React from 'react';
import classNames from 'classnames';

interface OptionProps {
    onClick: (value: string) => void,
    children: any,
    value: string,
    className: string,
    activeClassName?: string,
    active?: boolean,
    disabled?: boolean,
    title: string,
}

const Option = (props: OptionProps) => {
    const onOptionClick = () => {
        const { disabled, onClick, value } = props;
        if (!disabled) {
            onClick(value);
        }
    };

    const { children, className, activeClassName, active, disabled, title } = props;
    return (
        <div
            className={classNames(
                'rdw-option-wrapper',
                className,
                {
                    [`rdw-option-active ${activeClassName}`]: active,
                    'rdw-option-disabled': disabled
                }
            )}
            onClick={onOptionClick}
            aria-selected={active}
            title={title}
        >
            {children}
        </div>
    );
};

export default Option;
