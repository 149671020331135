import classNames from 'classnames';
import { buildResourceLink } from 'lib/communication';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

interface ImageLayoutProp {
    expanded: boolean,
    onExpandEvent: () => void,
    doCollapse: () => void,
    onChange: (img: string, height: string, width: string, alt: string) => void,
    config: {
        icon: string,
        className: string,
        title: string,
        uploadEnabled: boolean,
        uploadCallback: (file: File) => Promise<string>,
        defaultSize: {
            height: any,
            width: any,
        }
        popupClassName: string,
        urlEnabled: boolean,
        previewImage: boolean,
        inputAccept: string,
        alt: {
            present: boolean,
            mandatory: boolean
        },
    },
    translations: { [_: string]: string },
}

const ImageLayout = (props: ImageLayoutProp) => {
    const [imgSrc, setImgSrc] = useState('');
    const [dragEnter, setDragEnter] = useState(false);
    const [uploadHighlighted, setUploadHighlighted] = useState(props.config.uploadEnabled && !!props.config.uploadCallback);
    const [showImageLoading, setShowImageLoading] = useState(false);
    const [height, setHeight] = useState(props.config.defaultSize.height);
    const [width, setWidth] = useState(props.config.defaultSize.width);
    const [alt, setAlt] = useState('');
    const [maxSizeError, setMaxSizeError] = useState(false);

    const toggleShowImageLoading = () => {
        setShowImageLoading((state) => !state);
    };

    const uploadImage = (file: File) => {
        toggleShowImageLoading();
        const { uploadCallback } = props.config;
        if (file.size > 2097152) {
            setMaxSizeError(true);
            setShowImageLoading(false);
            setDragEnter(false);
            setImgSrc('');
        } else {
            setMaxSizeError(false);
            uploadCallback(file)
                .then((src) => {
                    setShowImageLoading(false);
                    setDragEnter(false);
                    setImgSrc(src);
                })
                .catch(() => {
                    setShowImageLoading(false);
                    setDragEnter(false);
                });
        }
    };

    useEffect(() => {
        const { config, expanded } = props;
        if (!expanded) {
            setImgSrc('');
            setDragEnter(false);
            setUploadHighlighted(config.uploadEnabled && !!config.uploadCallback);
            setShowImageLoading(false);
            setHeight(config.defaultSize.height);
            setWidth(config.defaultSize.width);
            setAlt('');
            setMaxSizeError(false);
        }
    }, [props]);

    const stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const onDragEnter = (event: React.DragEvent<HTMLElement>) => {
        stopPropagation(event);
        setDragEnter(true);
    };

    const onImageDrop = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragEnter(false);

        // Check if property name is files or items
        // IE uses 'files' instead of 'items'
        let data;
        let dataIsItems;
        if (event.dataTransfer.items) {
            data = event.dataTransfer.items;
            dataIsItems = true;
        } else {
            data = event.dataTransfer.files;
            dataIsItems = false;
        }
        for (let i = 0; i < data.length; i += 1) {
            if ((!dataIsItems) && data[i].type.match('^image/')) {
                const file = dataIsItems ? (data[i] as DataTransferItem).getAsFile() : data[i] as File;
                if (file) {
                    uploadImage(file);
                }
            }
        }
    };

    const showImageUploadOption = () => {
        setUploadHighlighted(true);
    };

    const addImageFromState = () => {
        const { onChange } = props;
        let h = height;
        let w = width;
        if (!Number.isNaN(h)) {
            h += 'px';
        }
        if (!Number.isNaN(w)) {
            w += 'px';
        }
        onChange(imgSrc, h, w, alt);
    };

    const showImageURLOption = () => {
        setUploadHighlighted(false);
    };

    const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            uploadImage(event.target.files[0]);
        }
    };

    const fileUploadClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    const renderAddImageModal = () => {
        const {
            config: {
                popupClassName,
                uploadEnabled,
                urlEnabled,
                previewImage,
                inputAccept,
                alt: altConf
            },
            doCollapse,
            translations
        } = props;
        return (
            <div
                className={classNames('rdw-image-modal', popupClassName)}
                onClick={stopPropagation}
            >
                <div className='rdw-image-modal-header'>
                    {uploadEnabled && (
                        <span
                            onClick={showImageUploadOption}
                            className='rdw-image-modal-header-option'
                        >
                            {translations['components.controls.image.fileUpload']}
                            <span
                                className={classNames('rdw-image-modal-header-label', { 'rdw-image-modal-header-label-highlighted': uploadHighlighted })}
                            />
                        </span>
                    )}
                    {urlEnabled && (
                        <span
                            onClick={showImageURLOption}
                            className='rdw-image-modal-header-option'
                        >
                            {translations['components.controls.image.byURL']}
                            <span
                                className={classNames('rdw-image-modal-header-label', { 'rdw-image-modal-header-label-highlighted': !uploadHighlighted })}
                            />
                        </span>
                    )}
                </div>
                {uploadHighlighted ? (
                    <div>
                        {maxSizeError && (
                            <div className='ImageLayout-maxSize-error'>
                                Wybrane zdjęcie przekracza rozmiar 2MB. Wybierz zdjęcie o mniejszym rozmiarze.
                            </div>
                        )}
                        <div onClick={fileUploadClick}>
                            <div
                                onDragEnter={onDragEnter}
                                onDragOver={stopPropagation}
                                onDrop={onImageDrop}
                                className={classNames('rdw-image-modal-upload-option', { 'rdw-image-modal-upload-option-highlighted': dragEnter })}
                            >
                                <label
                                    htmlFor='file'
                                    className='rdw-image-modal-upload-option-label'
                                >
                                    {previewImage && imgSrc ? (
                                        <img
                                            src={buildResourceLink(imgSrc)}
                                            alt={alt}
                                            className='rdw-image-modal-upload-option-image-preview'
                                        />
                                    ) : (
                                        imgSrc ? buildResourceLink(imgSrc) : 'Click to upload'
                                    )}
                                </label>
                                {showImageLoading ? (
                                    <div className='rdw-image-modal-spinner'>
                                        <Spinner animation='border' />
                                    </div>
                                ) : (
                                    undefined
                                )}
                            </div>
                            <input
                                type='file'
                                id='file'
                                accept={inputAccept}
                                onChange={selectImage}
                                className='rdw-image-modal-upload-option-input'
                            />
                        </div>
                    </div>
                ) : (
                    <div className='rdw-image-modal-url-section'>
                        <input
                            className='rdw-image-modal-url-input'
                            placeholder={translations['components.controls.image.enterlink']}
                            name='imgSrc'
                            onChange={(event) => {
                                setImgSrc(event.target.value);
                                setMaxSizeError(false);
                            }}
                            onBlur={(event) => {
                                setImgSrc(event.target.value);
                                setMaxSizeError(false);
                            }}
                            value={imgSrc}
                        />
                        <span className='rdw-image-mandatory-sign'>*</span>
                    </div>
                )}
                {altConf.present && (
                    <div className='rdw-image-modal-size'>
                        <span className='rdw-image-modal-alt-lbl'>Alt Text</span>
                        <input
                            onChange={(event) => setAlt(event.target.value)}
                            onBlur={(event) => setAlt(event.target.value)}
                            value={alt}
                            name='alt'
                            className='rdw-image-modal-alt-input'
                            placeholder='alt'
                        />
                        <span className='rdw-image-mandatory-sign'>
                            {altConf.mandatory && '*'}
                        </span>
                    </div>
                )}
                <div className='rdw-image-modal-size'>
                    &#8597;&nbsp;
                    <input
                        onChange={(event) => setHeight(event.target.value)}
                        onBlur={(event) => setHeight(event.target.value)}
                        value={height}
                        name='height'
                        className='rdw-image-modal-size-input'
                        placeholder='Height'
                    />
                    <span className='rdw-image-mandatory-sign'>*</span>
                    &nbsp;&#8596;&nbsp;
                    <input
                        onChange={(event) => setWidth(event.target.value)}
                        onBlur={(event) => setWidth(event.target.value)}
                        value={width}
                        name='width'
                        className='rdw-image-modal-size-input'
                        placeholder='Width'
                    />
                    <span className='rdw-image-mandatory-sign'>*</span>
                </div>
                <span className='rdw-image-modal-btn-section'>
                    <button
                        type='button'
                        className='rdw-image-modal-btn'
                        onClick={addImageFromState}
                        disabled={
                            !imgSrc || !height || !width || (altConf.mandatory && !alt)
                        }
                    >
                        {translations['generic.add']}
                    </button>
                    <button type='button' className='rdw-image-modal-btn' onClick={doCollapse}>
                        {translations['generic.cancel']}
                    </button>
                </span>
            </div>
        );
    };

    const {
        config: { icon, className, title },
        expanded,
        onExpandEvent,
        translations
    } = props;
    return (
        <div
            className='rdw-image-wrapper'
            aria-haspopup='true'
            aria-expanded={expanded}
            aria-label='rdw-image-control'
        >
            <div
                className={classNames('rdw-option-wrapper', className)}
                onClick={onExpandEvent}
                title={title || translations['components.controls.image.image']}
            >
                <img src={icon} alt='' />
            </div>
            {expanded ? renderAddImageModal() : undefined}
        </div>
    );
};

export default ImageLayout;
