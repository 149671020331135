export interface News {
    company?: number;
    title: string;
    dateStart: string;
    dateEnd: string;
    content: string;
    time?: string;
    id: number | undefined;
    links: string[];
    isArchived: boolean;
}

export type Role = 'master' | 'handlowiec' | 'dekarz';
export interface User {
    id: string;
    login: string;
    username: string;
    email: string;
    role: Role;
    isAdmin: boolean;
    name: string;
    phone: string;
    surname: string;
    token: string;
    tokenExpiration: number;
    company: string;
    companyIsod: string;
    guardian: string;
    guardianMail: string;
    guardianPhone: string;
    mag: string;
    logo: string;
    forcePwdChange: boolean;
    environment: string;
    promotionalProgramEnroll: string;
    promotionTermsAccept: string;
    promotionTermsChange: string;
    wydr: string[];
    currency: string;
    params: Param[];
}

export interface Param {
    ParamName: string;
    ParamValue: string;
}

export interface Jwt {
    token: string;
    tokenExpiration: number;
}

export enum OrderDirection {
    Asc = 'asc',
    Desc = 'desc'
}

export interface Billing {
    date: string;
    have: number;
    number: string;
    owing: number;
    payment: string;
    paymentDeadline: string;
    lateDays?: number;
    color?: string;
    onBalance: number;
}

export interface SummaryBillingRow {
    owing: number;
    have: number;
    onBalance: number;
}

export interface SummaryBilling {
    sum7orLess: SummaryBillingRow;
    sum8to14: SummaryBillingRow;
    sum15to21: SummaryBillingRow;
    sum22orMore: SummaryBillingRow;
    sum: SummaryBillingRow;
}

export interface SaleAgent {
    logo: string;
    login: string;
    firstName: string;
    lastName: string;
    date: string;
    blocked: boolean;
}

export interface Product {
    symKar: string;
    path: string[];
    description: string;
    descriptionEN: string;
    descriptionRU: string;
    unit: string;
    isDimension: boolean;
    dimensionDefault: number;
    dimensionCanEdit: boolean;
    dimensionMin: number | null;
    dimensionMax: number | null;
    remarks: string;
    additions: ProductAddition[];
    priceInfo: ProductPrice;
    additionalAssortment: AdditionalProduct[];
    isEditable?: boolean;
    image: string | null;
}

export interface FoundProduct {
    symKar: string;
    description: string;
    unit: string;
    image: string | null;
}

export interface AdditionalProduct {
    symKar: string;
    unit: string;
    tag: string;
}

export interface ProductAddition {
    name: string;
    default: boolean;
    chosen: boolean | null;
}

export interface Unit {
    unit: string;
    isDefault: boolean;
    converter: number;
    description: string;
}

export interface ProductPrice {
    price: number;
    priceWithFoil: number;
    discount: number;
    discountWithFoil: number;
    priceAfterDiscount: number;
    priceWithFoilAfterDiscount: number;
    vatRate: number;
    vat: number;
    vatWithFoil: number;
    gross: number;
    grossWithFoil: number;
    converterM2: number;
    units: Unit[];
}

export interface Order {
    id: string;
    orderNumber: string;
    customerOrderNumber: string;
    date: string;
    status: string;
    description: string;
    customerDescription: string;
    gross: number;
    net: number;
    vat: number;
    weight: number;
    isEditable: boolean;
    isDeletable: boolean;
    isPrintable: boolean;
}

export interface ProductInOrder {
    product: Product;
    additionsSelected: boolean[] | null;
    dimension: number | null;
    quantity: number;
    unit: string;
}

export interface DetailedOrder {
    products: ProductInOrder[];
    orderNumber?: string;
    customerOrderNumber?: string;
    description: string;
    customerDesc: string;
    address: string;
    rooferId?: number;
    rooferLogin: string | null;
    status?: string;
    addToExisting: boolean;
}

export type Option = '' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';

export interface PromotionalProduct {
    symKar: string;
    photo: string;
    description: string;
    price: number;
    points: number;
    options: Option[];
    group: string;
    isElectronicShipping: boolean;
}

export interface PromotionalOrder {
    date: string;
    quantity: number;
    description: string;
    price: number;
    points: number;
}

export interface PromotionalDelivery {
    symKar: string;
    delivery: string;
    description: string;
    points: number;
    price: number;
}

export interface Address {
    firstName: string;
    lastName: string;
    companyName: string;
    address: string;
    postalCode: string;
    city: string;
}

export type DeliveryOption = 'inputAddress' | 'distributorAddress' | 'selfPickup';

export interface ProductsGroup {
    description: string;
    level: number;
    path: string;
    hasProducts: boolean;
    groups: string[];
    image: string | null;
}

export interface GroupsAndProducts {
    products: Product[];
    groups: ProductsGroup[];
}

export interface RegisteredUserData {
    login: string;
    password: string;
}

export interface ProductInCart {
    product: Product;
    quantity: number;
    quantityJm: number;
    quantityM2: number;
    dimension: number | null;
    price: number;
    additionsSelected: boolean[] | null;
    mainProductSymkar: string | null;
    systemData?: GutteringSystemData;
    unit: Unit;
}

export type ProductsType = 'mainProducts' | 'additionalProducts';
export interface CartContent {
    summaryPrice: number;
    itemsCount: number;
    summaryPriceBeforeDiscount: number;
    summaryDiscont: number;
    summaryVat: number;
    productGroups: {
        groupName: string;
        mainProducts: MainProductsInCart;
        additionalProducts: ProductsInCart;
    }[];
    gutterGroups: {
        groupName: string;
        products: ProductsInCart
    }[];
}

export interface MainProductsInCart extends ProductsInCart {
    quantityJm: number;
    quantityM2: number;
}

export interface ProductsInCart {
    products: ProductInCart[];
    quantity: number;
    price: number;
}

export interface ProductInLocalCart {
    product: Product;
    quantity: number;
    dimension: number | null;
    additionsSelected: boolean[] | null;
    mainProductSymkar: string | null;
    mainProduct?: Product | null;
    unit: Unit;
}

export interface LocalCart {
    productGroups: {
        groupPath: string;
        mainProducts: ProductInLocalCart[];
        additionalProducts: ProductInLocalCart[];
    }[];
    gutterings: GutterProduct[];
    isod: IsodProduct[][];
}

export interface IsodProduct {
    product: Product;
    quantity: number;
    dimension: number;
    additionsSelected: boolean[] | null;
    mainProductSymkar?: null
}

export interface GutterProduct {
    product: Product;
    quantity: number;
    systemData?: GutteringSystemData;
}

export interface ImportedProduct {
    symKar: string,
    quantity: number;
    dimension: number | null;
    additionsSelected: boolean[] | null;
}

export interface RooferInfo {
    login: string;
    company: string;
    nip: string;
    phone: string;
    error?: string;
    serverError?: boolean;
}

export interface AddOrderRequestDTO {
    login: string;
    logo: string;
    description: string;
    orderId?: string | null;
    customerOrderNumber?: string;
    address: string;
    roofer: string | null;
    customerDesc: string;
    addToExisting: boolean;
    products: {
        symKar: string;
        quantity: number;
        unit: string;
        dimension: number | undefined;
        foil: string;
    }[];
}

export interface TermsAccepted {
    DATA: {
        Mes: string;
        Error: string;
    };
}

export interface TermsResponse {
    content: string;
}

export type ViewId = 'basic' | 'advanced' | 'treatment' | 'gutters' | 'plate';

export interface NotificationComponentData {
    isVisible: boolean;
    message: string;
    variant: string;
}

export interface GutteringSystemDataV1 {
    systemN: string;
    system: string;
    color: number;
    size: number;
}

export interface GutteringSystemDataV2 {
    gutterShape: string;
    material: string;
    grille: string;
    tubeShape: string;
    size: string;
    colorName: string;
}

export type GutteringSystemData = GutteringSystemDataV1 | GutteringSystemDataV2;

export interface GutteringProduct {
    id: number;
    nr: string;
    gutterShape: string;
    grille: string;
    tubeShape: string;
    material: string;
    size: string;
    symKar: string;
    file: string;
    listFile: string;
    color: string;
    colorName: string;
    fontColor: string;
    selectionColor: string;
    selectionBrighten: number;
    bgColor: string;
    namePL: string;
    nameEN: string;
    nameRU: string;
    nameDE: string;
    posX: number;
    posY: number;
    posZ: number;
}

export interface GutteringAssortment {
    products: GutteringProduct[];
}

export interface GutteringSystem {
    name: string;
    systems: {
        name: string;
        colors: {
            name: string;
        }[];
        sizes: {
            name: string;
        }[];
    }[];
}

export interface WebserviceTestResult {
    date: string;
    testCasesResults: TestCaseResult[];
}

export interface TestCaseResult {
    className: string;
    exceptionMessage: string;
    prettifiedClassName: string;
    prettifiedMethodName: string;
    size: string;
    status: string;
    time: string;
}

export interface ProductInCartTable extends ProductInCart {
    groupPath: string;
    indexInGroup: number;
    isGutter: boolean;
    isIsod: boolean;
}

export interface AdditionalItemsSummary {
    quantity: number;
    netto: number;
    vat: number;
    gross: number;
}

export interface CartTableItem {
    title: string;
    identifier: string;
    products: ProductInCartTable[];
    totalPrice: number;
    totalNetto: number;
    totalVat: number;
    totalQuantity: number;
    totalQuantityJM: number;
    totalQuantityM2: number;
    additionalItemsSummary: AdditionalItemsSummary;
}

export interface CartTableContent {
    mainItems: { [key: string]: CartTableItem };
    additionalItems: { [key: string]: { [key: string]: CartTableItem } };
    isodItems: { [key: string]: CartTableItem };
    gutteringsItems: { [key: string]: CartTableItem };
}

export interface OrderPartStatus {
    orderId: string;
    kar: string;
    status: string;
    producer: string;
}

export interface OrderStatus {
    show: boolean;
    order: Order;
    parts: OrderPartStatus[]
}

export type ISODAction = 'ISODClosed' | 'ISODPoll' | 'ISODSendProducts' | 'ISODReplaceProducts' | 'ISODMergeProducts';

export interface ISODPackage {
    action: ISODAction,
    data?: ISODPackageData | boolean | object
}

export interface ISODPackageData {
    antykondensat: boolean,
    asortyment: ISODProduct[],
    folia: boolean,
    kurs: number,
    scalKoszyk: boolean,
    soundproofMat: boolean,
    waluta: string,
}

export interface ISODProduct {
    symkar: string,
    ilosc: number,
    dlugosc: string,
    jm: string
}

export interface ServiceWorkType {
    id: Number,
    from: Date,
    to: Date,
    message: string
}
export const OrderStatusNames = ['accepted', 'waiting', 'processing', 'completed', 'closed'] as const;
export type OrderStatusName = typeof OrderStatusNames[number];

export const OrderStatusNameToLabel: Record<OrderStatusName, string> = {
    accepted: 'Przyjęte',
    waiting: 'Oczekujące',
    processing: 'Realizowane',
    completed: 'Zrealizowane',
    closed: 'Zamknięte'
} as const;

export const OrderStatusLabelToName: Record<string, OrderStatusName> = {
    Przyjęte: 'accepted',
    Oczekujące: 'waiting',
    Realizowane: 'processing',
    Zrealizowane: 'completed',
    Zamknięte: 'closed'
} as const;

export interface CacheManagement {
    last_execution_time: number;
    progress: number;
    description: string;
    id: number,
    date: Date | null,
    name: string
}

export interface EditAssortmentImage {
    message: string,
    error: boolean,
    filename: string,
}

export interface StatisticDay {
    endpoints_requests: { [key: string]: number };
    hour_requests: number[];
    total_requests: number;
    users: string[];
}

export interface Statistics {
    [key: string]: StatisticDay;
}
