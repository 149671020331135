import React, { useState } from 'react';
import UserRegistrationForm from 'components/UserRegistrationForm';
import { addSalesAgent } from 'lib/communication/promotionalProgram';
import { UserRegistrationInfo, AppState } from 'reducers';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner/Spinner';
import { Modal, Button } from 'react-bootstrap';

const TraderRegistration = () => {
    const isFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
    const [error, setError] = useState(false);
    const [errorToShow, setErrorToShow] = useState<string>();
    const [newUser, setNewUser] = useState<UserRegistrationInfo>();
    const [show, setShow] = useState(false);

    const handleSubmit = (newUserInfo: UserRegistrationInfo) => {
        setNewUser(newUserInfo);
        const fetchData = async () => {
            try {
                const result = await addSalesAgent(newUserInfo);
                if (result) {
                    setNewUser(undefined);
                }
            } catch (ex) {
                setErrorToShow((ex as Error).message);
                setError(true);
            }
        };
        fetchData();
        setShow(true);
    };

    if (isFetching || error) {
        return <Spinner showError={error} />;
    }

    return (
        <>
            <h2>Rejestracja handlowca</h2>
            <UserRegistrationForm userType='trader' onSubmit={handleSubmit} userInfo={newUser} />
            <Modal show={show} onHide={() => { setShow(false); setErrorToShow(undefined); }}>
                <Modal.Body>
                    {errorToShow === undefined ?
                        `Rejestracja przebiegła pomyślnie, na numer telefonu została wysłana wiadomość z informacjami logowania.` :
                        errorToShow}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => { setShow(false); setErrorToShow(undefined); }}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TraderRegistration;
