import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getTermsAndConditions } from 'lib/communication/promotionalProgram';
import Spinner from 'components/Spinner';
import ReactMarkdown from 'react-markdown';

const CookiePolicy = () => {
    const cookiePolicy = useSelector((state: AppState) => state.data.promotionalProgram.cookiePolicy);
    const isFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => { setError(!(await getTermsAndConditions('cookie_policy'))); };
        fetchData();
    }, []);

    if (isFetching || error) {
        return <Spinner showError={error} />;
    }

    return (
        <div style={{ textAlign: 'left', padding: '0 30px' }}>
            <ReactMarkdown source={cookiePolicy} escapeHtml={false} />
        </div>
    );
};

export default CookiePolicy;
