import React, { useState } from 'react';
import { Pagination as Pag } from 'react-bootstrap';
import _ from 'lodash';
import './Pagination.scss';

const DEFAULT_WINDOW_SIZE = 2;

export interface PaginationProps {
    defaultSelected?: number;
    windowSize?: number;
    size: number;
    href?: (page: number) => string;
    onSelect?: (selected: number) => void;
    selected?: number;
}

const Pagination = ({ defaultSelected = 0, windowSize = DEFAULT_WINDOW_SIZE, size, href = (p) => '#', onSelect = _.identity, selected = undefined }: PaginationProps) => {
    const [page, setPage] = useState(defaultSelected);
    const selectedPage = selected ?? page;

    const clampPage = (p: number) => {
        p = _.clamp(p, 0, size - 1);
        setPage(p);
        onSelect(p);
    };

    const handleClick = (p: number) => (event: React.MouseEvent) => {
        event.preventDefault();
        clampPage(p);
    };

    windowSize = Math.max(0, windowSize);
    let pages;

    if (size <= 7) {
        pages = _.range(0, size);
    } else {
        pages = (selectedPage >= 4 && selectedPage <= size - 5) ? _.range(selectedPage - 1, selectedPage + 2) : (selectedPage > size - 5 ? _.range(size - 5, size - 1) : _.range(0, 5));
    }

    const Item = ({ num }: { num: number }) => (
        <Pag.Item style={{ width: '32px' }} href={href(num)} active={num === selectedPage} onClick={handleClick(num)}>
            {num + 1}
        </Pag.Item>
    );

    if (size <= 1) {
        return null;
    }

    return (
        <Pag size='sm' className='mb-0'>
            <Pag.Prev
                disabled={selectedPage === 0}
                href={href(selectedPage - 1)}
                onClick={handleClick(selectedPage - 1)}
                style={{ width: '32px' }}
            />
            {size <= 7 ?
                _.map(pages, (num) => (
                    <Item key={num} num={num} />
                )) : (
                    <>
                        {(selectedPage >= 4) && <Item num={0} />}
                        {(selectedPage >= 4) && <Pag.Ellipsis disabled style={{ width: '32px' }} />}
                        {_.map(pages, (num) => (
                            <Item key={num} num={num} />
                        ))}
                        {selectedPage <= size - 5 && <Pag.Ellipsis disabled style={{ width: '32px' }} />}
                        <Item num={size - 1} />
                    </>
                )}
            <Pag.Next
                href={href(selectedPage + 1)}
                disabled={selectedPage === size - 1}
                onClick={handleClick(selectedPage + 1)}
                style={{ width: '32px' }}
            />
        </Pag>
    );
};

export default Pagination;
