import React, { useState, useEffect } from 'react';
import { Table, Form, Col, Row, Button, Alert, ListGroup, Navbar, Nav, Collapse } from 'react-bootstrap';
import './PromotionProducts.scss';
import ImageThumbnail from 'components/ImageThumbnail';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import { AppState } from 'reducers';
import { useSelector } from 'react-redux';
import { PromotionalProduct } from 'lib/types';
import _ from 'lodash';
import Spinner from 'components/Spinner';

import { buildResourceLink } from 'lib/communication';
import { useHistory } from 'react-router-dom';
import { TiArrowRightThick, TiArrowUpThick } from 'react-icons/ti';
import { FaRedo } from 'react-icons/fa';
import NumberInput from 'components/NumberInput';

interface PromotionProductsListProps {
    error: boolean;
    remainingPoints: number;
    selectedCount: Map<PromotionalProduct, number[]>;
    setShowSummary: (_: boolean) => void;
    setSelectedCount: React.Dispatch<React.SetStateAction<Map<PromotionalProduct, number[]>>>;
}

const PromotionProductsList = ({ error, remainingPoints, selectedCount, setShowSummary, setSelectedCount }: PromotionProductsListProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const history = useHistory();

    const promotionalProducts = useSelector((state: AppState) => state.data.promotionalProgram.products);
    const isFetchingProducts = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetchingProducts);

    const grandTotalPoints = Array.from(selectedCount.entries())
        .reduce((previous, entity) => previous + entity[1].reduce((prev, curr) => prev + curr) * entity[0].points, 0);

    const [items, setItems] = useState([] as PromotionalProduct[]);
    const [productGroup, setProductGroup] = useState(history.location.state as String || 'Wszystkie');
    const [showScrollUpButton, setShowScrollUpButton] = useState(false);
    const [selectedProduct, setSelected] = useState(-1);

    useEffect(() => {
        const filteredProducts = _.filter(promotionalProducts, (product) => product.group === productGroup || productGroup === 'Wszystkie');
        setItems(filteredProducts);
    }, [promotionalProducts, productGroup]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 300) {
                setShowScrollUpButton(true);
            } else {
                setShowScrollUpButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleCountChange = (value: number, product: PromotionalProduct, option: number = 0) => {
        setSelectedCount((prev) => {
            const map = new Map(prev);
            map.get(product)![option] = value;
            return map;
        });
    };

    const emptyInputFields = () => {
        _.forEach(items, (product) => {
            _.each(product.options, (option, optionIndex) => {
                handleCountChange(0, product, optionIndex);
            });
        });
    };

    const makeCountInputs = (product: PromotionalProduct) => _.map(product.options, (option, optionIndex) => (
        <Form.Group
            as={Row}
            noGutters
            className='PromotionProducts-variant ml-auto'
            controlId={`product-count-${product.symKar}${option}`}
            key={`${optionIndex}-${option}`}
        >
            <Form.Label column className='pr-1'>
                {option}
            </Form.Label>
            <Col>
                <NumberInput
                    className='ml-auto justify-content-end'
                    value={selectedCount.get(product) ? selectedCount.get(product)![optionIndex] : 0}
                    min={0}
                    setValue={(value: number) => handleCountChange(value, product, optionIndex)}
                    stopPropagation={!isDesktop}
                    disabledEdit={selectedCount.get(product) ? (selectedCount.get(product)![optionIndex] === 0) : false}
                />
            </Col>
        </Form.Group>
    ));

    useEffect(() => {
        (document?.activeElement as HTMLElement).blur();
        const group = history.location.state ?? 'Wszystkie';
        setProductGroup(group as string);
    }, [history.location.state]);

    return (
        <>
            <Form onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                setShowSummary(true);
                event.currentTarget.scrollIntoView({ behavior: 'smooth' });
            }}
            >
                <h3>Artykuły promocyjne</h3>
                {
                    (isFetchingProducts || error) ? (
                        <Spinner centered={false} showError={error} />
                    ) : (
                        <>
                            <Navbar
                                className='sub-navbar'
                                expand='lg'
                                onSelect={(eventKey) => {
                                    history.push(history.location.pathname, eventKey);
                                }}
                            >
                                <Nav variant='pills' fill justify className='flex-grow-1 d-flex flex-wrap' activeKey={productGroup}>
                                    <Nav.Item key='Wszystkie'>
                                        <Nav.Link eventKey='Wszystkie'>Wszystkie</Nav.Link>
                                    </Nav.Item>
                                    {
                                        _.uniq(_.map(promotionalProducts, 'group')).map((value) => (
                                            <Nav.Item key={value}>
                                                <Nav.Link eventKey={value}>{value}</Nav.Link>
                                            </Nav.Item>
                                        ))
                                    }
                                </Nav>
                            </Navbar>
                            {
                                isDesktop ? (
                                    <Table size='sm' borderless striped className='tr3 tr4 tr5 products-table'>
                                        <thead>
                                            <tr>
                                                <th>Foto</th>
                                                <th>Opis</th>
                                                <th className='PromotionProducts-number-th'>Ilość</th>
                                                <th>Punkty</th>
                                                <th>Cena</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_.map(items, (product) => (
                                                <tr
                                                    key={product.symKar}
                                                    className={
                                                        selectedCount.get(product)?.some((num) => num !== 0) ?
                                                            'PromotionProducts-selected-row' :
                                                            ''
                                                    }
                                                >
                                                    <td style={{ padding: 5 }} className='PromotionProducts-photo-td'>
                                                        <ImageThumbnail
                                                            src={buildResourceLink(product.photo)}
                                                            alt=''
                                                            aria-describedby={`PromotionProducts-desc-${product.symKar}`}
                                                        />
                                                    </td>
                                                    <td id={`PromotionProducts-desc-${product.symKar}`}>{product.description}</td>
                                                    <td style={{ padding: '0 20px 0 0' }} className='count-inputs'>{makeCountInputs(product)}</td>
                                                    <td>{product.points}</td>
                                                    <td>{product.price.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <ListGroup variant='flush' className='mb-3'>
                                        {_.map(items, (product, productIndex) => (
                                            <ListGroup.Item
                                                key={product.symKar}
                                                className={
                                                    `block-item${selectedCount.get(product)?.some((num) => num !== 0) ? ' PromotionProducts-selected-row' : ''
                                                    }${selectedProduct === productIndex ? ' rotate' : ''}`
                                                }
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelected(selectedProduct !== productIndex ? productIndex : -1);
                                                }}
                                            >
                                                <p className='PromotionProducts-photo-desc text-left clearfix'>
                                                    <span className='PromotionProducts-photo-td float-left'>
                                                        <ImageThumbnail
                                                            src={buildResourceLink(product.photo)}
                                                            alt=''
                                                            aria-describedby={`PromotionProducts-desc-${product.symKar}`}
                                                        />
                                                    </span>
                                                    <span id={`PromotionProducts-desc-${product.symKar}`} className='PromotionProducts-desc d-flex flex-column justify-content-center'>
                                                        {product.description}
                                                        <div className='PromotionsProducts-price-points d-flex justify-content-around'>
                                                            <div style={{ whiteSpace: 'nowrap' }}>
                                                                <b>Punkty: </b>
                                                                {product.points}
                                                            </div>
                                                            <div style={{ whiteSpace: 'nowrap' }}>
                                                                <b>Cena: </b>
                                                                {product.price.toFixed(2)}
                                                            </div>
                                                        </div>
                                                        <Collapse in={selectedProduct === productIndex}>
                                                            <section>
                                                                <div className='d-flex flex-column'>
                                                                    {makeCountInputs(product)}
                                                                </div>
                                                            </section>
                                                        </Collapse>
                                                    </span>

                                                </p>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )
                            }
                            <div className='PromotionProducts-summary-box'>
                                <div className='PromotionProducts-summary-top'>
                                    {
                                        remainingPoints < 0 && (
                                            <Alert variant='danger' className='PromotionProducts-points-warning'>
                                                Niewystarczająca ilość punktów.
                                            </Alert>
                                        )
                                    }
                                    {
                                        showScrollUpButton && (
                                            <Button className='ml-auto' onClick={scrollToTop}>
                                                {isDesktop ? 'Powróć na górę' : <TiArrowUpThick />}
                                            </Button>
                                        )
                                    }
                                </div>
                                {
                                    grandTotalPoints > 0 && (
                                        <div>
                                            <div className='PromotionProducts-summary-content'>
                                                <Button type='button' onClick={emptyInputFields}>
                                                    {isDesktop ? 'Resetuj zamówienie' : <FaRedo style={{ transform: 'scale(-1, 1)' }} />}

                                                </Button>

                                                <div>
                                                    <div className='PromotionProducts-summary-points-title'>Koszt zamówienia</div>
                                                    <div>{grandTotalPoints}</div>
                                                </div>

                                                <div>
                                                    <div className='PromotionProducts-summary-points-title'>Pozostanie punktów</div>
                                                    <div className={remainingPoints < 0 ? 'text-danger' : ''}>{remainingPoints}</div>
                                                </div>

                                                <Button
                                                    type='submit'
                                                    disabled={_.every(
                                                        _.map(Array.from(selectedCount.values()), (sc) => _.every(sc, (c) => c === 0)),
                                                        (res) => res === true
                                                    ) || remainingPoints < 0}
                                                >
                                                    {isDesktop ? 'Przejdź do podsumowania' : <TiArrowRightThick />}
                                                </Button>

                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                    )
                }
            </Form>
        </>
    );
};

export default PromotionProductsList;
