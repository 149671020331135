export interface TreeNode {
    childrenNodes: TreeNode[];
    [key: string]: any;
}

export const makeRoot = (childrenNodes: TreeNode[]): TreeNode => ({ childrenNodes });

export const isLeaf = (node: TreeNode) => node.childrenNodes.length === 0;

export const foldTree = <T>(func: (node: TreeNode, childrenNodes: T[]) => T, tree: TreeNode): T => func(tree, isLeaf(tree) ? [] : tree.childrenNodes.map((child: TreeNode) => foldTree(func, child)));

export const mapTree = <T>(func: (node: TreeNode) => T, tree: TreeNode): T => foldTree((node: TreeNode, childrenNodes: TreeNode[]) => ({ ...func(node), childrenNodes }), tree);

export const filterTree = (predicate: (node: TreeNode) => boolean, tree: TreeNode): TreeNode => foldTree((node: TreeNode, accumulator: TreeNode[]) => ({ ...node, childrenNodes: accumulator.filter(predicate) }), tree);

export const firstOfTree = (predicate: (node: TreeNode) => boolean, tree: TreeNode): TreeNode | null => {
    if (predicate(tree)) return tree;

    // eslint-disable-next-line no-restricted-syntax
    for (const child of tree.childrenNodes) {
        const result = firstOfTree(predicate, child);
        if (result !== null) return result;
    }
    return null;
};

export const treeToList = (tree: TreeNode): TreeNode[] => {
    const result: TreeNode[] = foldTree((node: TreeNode, childrenNodes: ((nodes: TreeNode[]) => TreeNode[])[]) => (partialResult: TreeNode[]): TreeNode[] => {
        const res = childrenNodes.reduceRight((acc: TreeNode[], val: (nodes: TreeNode[]) => TreeNode[]) => val(acc), partialResult);

        res.push(node);
        return res;
    }, tree)([]);

    return result.reverse();
};
