import React, { useState } from 'react';
import UserRegistrationForm from 'components/UserRegistrationForm';
import { addRoofer } from 'lib/communication/promotionalProgram';
import { UserRegistrationInfo, AppState } from 'reducers';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner/Spinner';
import { Modal, Button } from 'react-bootstrap';

const RooferRegistration = () => {
    const isFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
    const [error, setError] = useState(false);
    const [errorToShow, setErrorToShow] = useState<string | null>(null);
    const [newUser, setNewUser] = useState<UserRegistrationInfo | undefined>(undefined);
    const [show, setShow] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);

    const handleSumbit = (newUserInfo: UserRegistrationInfo) => {
        setNewUser(undefined);
        setInvalidEmail(false);
        const fetchData = async () => {
            try {
                setError(!(await addRoofer(newUserInfo)));
                setShow(true);
            } catch (ex) {
                const e = ex as Error;
                if (e.message === 'Error: Błąd rejestracji dekarza. Podany email jest już używany.') {
                    setInvalidEmail(true);
                }
                setNewUser(newUserInfo);
            }
        };
        fetchData();
    };

    if (isFetching || error) {
        return <Spinner showError={error} />;
    }

    return (
        <>
            <h2>Rejestracja dekarza</h2>
            <UserRegistrationForm userType='roofer' onSubmit={handleSumbit} userInfo={newUser} invalidEmail={invalidEmail} />
            <Modal show={show} onHide={() => { setShow(false); setErrorToShow(null); }}>
                <Modal.Body>{errorToShow ?? `Użytkownik zarejestrowany pomyślnie. Dane logowania zostały wysłane na podany numer telefonu.`}</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => { setShow(false); setErrorToShow(null); }}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RooferRegistration;
