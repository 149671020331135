import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import './SortButtons.scss';
import { OrderDirection } from 'lib/types';

interface SortButtonsProps {
    value?: SortButtonsValue;
    orderBy?: string;
    onChange?: (val: SortButtonsValue) => void;
}
export interface SortButtonsValue {
    direction: OrderDirection;
    group: string;
}

const SortButtons = ({ value, orderBy, onChange = () => { } }: SortButtonsProps) => (
    <ToggleButtonGroup
        type='radio'
        name='sa'
        value={value}
        onChange={(val) => onChange(val as SortButtonsValue)}
        className='sort-buttons'
    >
        <ToggleButton
            value={{ direction: OrderDirection.Asc, group: value?.group }}
            variant={value?.direction === OrderDirection.Asc && orderBy === value.group ? 'dark' : 'link'}
            size='sm'
        >
            <FaAngleUp />
        </ToggleButton>
        <ToggleButton
            value={{ direction: OrderDirection.Desc, group: value?.group }}
            variant={value?.direction === OrderDirection.Desc && orderBy === value.group ? 'dark' : 'link'}
            size='sm'
        >
            <FaAngleDown />
        </ToggleButton>
    </ToggleButtonGroup>
);

export default SortButtons;
