import { userService } from 'services/user.service';
import store from 'store';
import { sessionActions, dataActions, requestsActions } from 'reducers';
import { User } from 'lib';
import { ServiceWorkType } from './types';

export const ADDRESS = new Proxy({ value: '' }, {
    get(target, prop, receiver) {
        Reflect.set(target, 'value', store.getState().conf.zamowieniaServiceAddress);
        const prim = Reflect.get(target, 'value');
        const value = prim[prop as any];
        return typeof value === 'function' ? (value as Function).bind(prim) : value;
    }
});

export const API_ADDRESS = new Proxy({ value: '' }, {
    get(target, prop, receiver) {
        Reflect.set(target, 'value', `${ADDRESS}api/`);
        const prim = Reflect.get(target, 'value');
        const value = prim[prop as any];
        return typeof value === 'function' ? (value as Function).bind(prim) : value;
    }
});

interface WebServiceResponse {
    data: any;
    measuredTimes: any;
}

interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

const appendJWTAuthHeaders = (headers: Headers) => {
    const user: User | null = userService.get();
    if (user) {
        const authMessage = `Bearer ${user.token}`;
        headers.append('Authorization', authMessage);
    }
};

const prepareRequest = (args: RequestInit) => {
    if (!args.headers || !(args.headers instanceof Headers)) {
        args.headers = new Headers();
    }
    appendJWTAuthHeaders(args.headers);
};

const http = async <T>(
    request: RequestInfo
): Promise<HttpResponse<T>> => {
    const response: HttpResponse<T> = await fetch(request);
    let disableUnidentifiedError: boolean = false;
    if (response.headers.get('Content-Type') !== 'application/pdf' && response.headers.get('Content-Type') !== 'application/zip') {
        try {
            const parsed = await response.json();
            if (parsed.data && parsed.data.error && parsed.wsErr) {
                store.dispatch(requestsActions.setErrorMessage(parsed.data.error));
            }
            if (parsed.data && parsed.data.error && parsed.data.error === 'User not authenticated or invalid token') {
                store.dispatch(requestsActions.setInvalidToken(true));
                disableUnidentifiedError = true;
            } else {
                store.dispatch(requestsActions.setInvalidToken(false));
            }
            if (parsed.error && parsed.error === 'Too Many Attempts.') {
                store.dispatch(requestsActions.setTooManyAttempts(true));
                disableUnidentifiedError = true;
            } else {
                store.dispatch(requestsActions.setTooManyAttempts(false));
            }
            response.parsedBody = (parsed as WebServiceResponse).data;
        } catch (ex) {
            throw new Error('Cannot parse response body to json');
        }
    }
    if (response.status === 503) {
        const serviceWork = response.parsedBody as any as ServiceWorkType;
        store.dispatch(dataActions.setServiceWorks([serviceWork]));
    } else if (!response.ok) {
        const responseMessage: any = response.parsedBody;
        if (response.status === 401 || response.status === 403) {
            userService.logout();
            throw new Error(responseMessage.error);
        } else {
            if (!disableUnidentifiedError) {
                store.dispatch(requestsActions.setErrorMessage('Wystąpił niezidentyfikowany błąd, spróbuj ponownie później.'));
            }
            throw new Error(responseMessage.error);
        }
    }
    return response;
};

export const get = async <T>(
    path: string,
    args: RequestInit = { method: 'get' }
): Promise<HttpResponse<T>> => {
    prepareRequest(args);
    return await http<T>(new Request(path, args));
};

export const post = async <T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'post',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body)
    }
): Promise<HttpResponse<T>> => {
    prepareRequest(args);
    return await http<T>(new Request(path, args));
};

export const put = async <T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'put',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body)
    }
): Promise<HttpResponse<T>> => {
    prepareRequest(args);
    return await http<T>(new Request(path, args));
};

export const del = async <T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'delete',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body)
    }
): Promise<HttpResponse<T>> => {
    prepareRequest(args);
    return await http<T>(new Request(path, args));
};

export const buildResourceLink = (path: string): string => (
    path.startsWith('http://') || path.startsWith('https://') ? path : `${ADDRESS}${path}`
);

export const logout = () => {
    store.dispatch(sessionActions.setUser(null));
    store.dispatch(dataActions.setUserInfo(null));
    store.dispatch(dataActions.clearLocalCart());
    store.dispatch(dataActions.clearLocalEditCart());
    store.dispatch(dataActions.clearGroupsAndProducts());
    store.dispatch(dataActions.resetOrdersList());
};
