import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Option from './options';
import { Spinner } from 'react-bootstrap';

interface LinkLayoutProps {
    expanded: boolean,
    onExpandEvent: () => void,
    onChange: (type: string, title: string, target: string, targetOption: object) => void,
    config: {
        options: Array<string>;
        className: string,
        title: string,
        defaultTargetOption: string,
        link: {
            className: string;
            icon: string;
            title: string;
        }
        unlink: {
            className: string;
            icon: string;
            title: string;
        }
        popupClassName: string,
    }
    currentState: {
        link: {
            target: string,
            targetOption: string,
            title: string,
        },
        selectionText: string,
    }
    doCollapse: () => void,
    translations: { [_: string]: string },
}

const LinkLayout = (props: LinkLayoutProps) => {
    const [state, setState] = useState({
        showModal: false,
        linkTarget: '',
        linkTitle: '',
        linkTargetOption: props.config.defaultTargetOption
    });
    const [uploadHighlighted, setUploadHighlighted] = useState(true);
    const [maxSizeError, setMaxSizeError] = useState(false);
    const [dragEnter, setDragEnter] = useState(false);
    const [showFileLoading, setShowFileLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const [file, setFile] = useState(new File([], ''));
    const [URLLink, setURLLink] = useState('');

    const eventSetState = (e: { target: { name: any; value: any; }; }) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const signalExpandShowModal = () => {
        const {
            onExpandEvent,
            currentState: { link, selectionText }
        } = props;
        const { linkTargetOption } = state;
        onExpandEvent();
        setState({
            showModal: true,
            linkTarget: (link && link.target) || '',
            linkTargetOption: (link && link.targetOption) || linkTargetOption,
            linkTitle: (link && link.title) || selectionText
        });
    };

    const stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    const onDragEnter = (event: React.DragEvent<HTMLElement>) => {
        stopPropagation(event);
        setDragEnter(true);
    };

    const toggleshowFileLoading = () => {
        setShowFileLoading((s) => !s);
    };

    const showLinkUploadOption = () => {
        setUploadHighlighted(true);
    };

    const showLinkURLOption = () => {
        setUploadHighlighted(false);
    };

    const uploadFile = (f: File) => {
        toggleshowFileLoading();
        if (f.size > 104857600) {
            setMaxSizeError(true);
            setShowFileLoading(false);
            setDragEnter(false);
            setImgSrc('');
            setFile(new File([], ''));
            setState({ ...state, linkTarget: '' });
        } else {
            setMaxSizeError(false);
            setFile(f);
            setImgSrc(f.name);
            setShowFileLoading(false);
            setDragEnter(false);
            setState({ ...state, linkTarget: `/storage/${f.name}` });
        }
    };

    const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            uploadFile(event.target.files[0]);
        }
    };

    const onFileDrop = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragEnter(false);

        // Check if property name is files or items
        // IE uses 'files' instead of 'items'
        let data;
        let dataIsItems;
        if (event.dataTransfer.items) {
            data = event.dataTransfer.items;
            dataIsItems = true;
        } else {
            data = event.dataTransfer.files;
            dataIsItems = false;
        }
        for (let i = 0; i < data.length; i += 1) {
            if ((!dataIsItems) && (data[i].type.match('^image/') || data[i].type.match('application/pdf'))) {
                const f = dataIsItems ? (data[i] as DataTransferItem).getAsFile() : data[i] as File;
                if (f) {
                    uploadFile(f);
                }
            }
        }
    };

    useEffect(() => {
        if (!state.linkTarget.startsWith('http://') && !state.linkTarget.startsWith('https://') && !state.linkTarget.startsWith('/storage')) {
            setURLLink(`https://${state.linkTarget}`);
        } else {
            setURLLink(state.linkTarget);
        }
    }, [state.linkTarget]);

    const updateTargetOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            linkTargetOption: event.target.checked ? '_blank' : '_self'
        });
    };

    const addLink = () => {
        const { linkTitle, linkTargetOption } = state;
        const linkTarget = URLLink;
        props.onChange('link', linkTitle, linkTarget, { linkTargetOption, file: uploadHighlighted ? file : undefined });
        props.doCollapse();
    };

    const removeLink = () => {
        const { linkTitle, linkTargetOption } = state;
        const linkTarget = URLLink;
        props.onChange('unlink', linkTitle, linkTarget, { linkTargetOption });
    };
    const renderAddLinkModal = () => {
        const {
            config: { popupClassName },
            doCollapse,
            translations
        } = props;
        const { linkTitle, linkTarget, linkTargetOption } = state;
        return (
            <div
                className={classNames('rdw-link-modal', popupClassName)}
                onClick={stopPropagation}
            >
                <div className='rdw-image-modal-header'>
                    <span
                        onClick={showLinkUploadOption}
                        className='rdw-image-modal-header-option'
                    >
                        {translations['components.controls.image.fileUpload']}
                        <span
                            className={classNames('rdw-image-modal-header-label', { 'rdw-image-modal-header-label-highlighted': uploadHighlighted })}
                        />
                    </span>
                    <span
                        onClick={showLinkURLOption}
                        className='rdw-image-modal-header-option'
                    >
                        {translations['components.controls.image.byURL']}
                        <span
                            className={classNames('rdw-image-modal-header-label', { 'rdw-image-modal-header-label-highlighted': !uploadHighlighted })}
                        />
                    </span>
                </div>
                {uploadHighlighted ? (
                    <div>
                        {maxSizeError && (
                            <div className='ImageLayout-maxSize-error'>
                                Wybrany plik przekracza rozmiar 100MB. Wybierz plik o mniejszym rozmiarze.
                            </div>
                        )}
                        <div>
                            <div
                                onDragOver={stopPropagation}
                                onDragEnter={onDragEnter}
                                onDrop={onFileDrop}
                                className={classNames('rdw-image-modal-upload-option', { 'rdw-image-modal-upload-option-highlighted': dragEnter })}
                            >
                                <label
                                    htmlFor='file'
                                    className='rdw-image-modal-upload-option-label'
                                >
                                    {imgSrc || 'Click to upload'}
                                </label>
                                {showFileLoading ? (
                                    <div className='rdw-image-modal-spinner'>
                                        <Spinner animation='border' />
                                    </div>
                                ) : (
                                    undefined
                                )}
                            </div>
                            <input
                                type='file'
                                id='file'
                                accept='image/gif,image/jpeg,image/jpg,image/png,image/svg,application/pdf'
                                onChange={selectFile}
                                className='rdw-image-modal-upload-option-input'
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <label className='rdw-link-modal-label' htmlFor='linkTarget'>
                            {translations['components.controls.link.linkTarget']}
                        </label>
                        <input
                            id='linkTarget'
                            className='rdw-link-modal-input'
                            onChange={eventSetState}
                            name='linkTarget'
                            value={linkTarget}
                        />
                    </div>
                )}
                <label className='rdw-link-modal-label' htmlFor='linkTitle'>
                    {translations['components.controls.link.linkTitle']}
                </label>
                <input
                    id='linkTitle'
                    className='rdw-link-modal-input'
                    onChange={eventSetState}
                    name='linkTitle'
                    value={linkTitle}
                />
                <label
                    className='rdw-link-modal-target-option'
                    htmlFor='openLinkInNewWindow'
                >
                    <input
                        id='openLinkInNewWindow'
                        type='checkbox'
                        defaultChecked={linkTargetOption === '_blank'}
                        value={linkTargetOption}
                        onChange={updateTargetOption}
                    />
                    <span>
                        {translations['components.controls.link.linkTargetOption']}
                    </span>
                </label>
                <span className='rdw-link-modal-buttonsection'>
                    <button
                        type='button'
                        className='rdw-link-modal-btn'
                        onClick={addLink}
                        disabled={!linkTarget || !linkTitle}
                    >
                        {translations['generic.add']}
                    </button>
                    <button type='button' className='rdw-link-modal-btn' onClick={doCollapse}>
                        {translations['generic.cancel']}
                    </button>
                </span>
            </div>
        );
    };

    const {
        config: { options, link, className, unlink },
        expanded,
        translations
    } = props;
    const showModal = state;
    return (
        <div
            className={classNames('rdw-link-wrapper', className)}
            aria-label='rdw-link-control'
        >
            {options.indexOf('link') >= 0 && (
                <Option
                    value='unordered-list-item'
                    className={classNames(className)}
                    onClick={signalExpandShowModal}
                    aria-haspopup='true'
                    aria-expanded={state.showModal}
                    title={link.title || translations['components.controls.link.link']}
                >
                    <img src={link.icon} alt='' />
                </Option>
            )}
            {options.indexOf('unlink') >= 0 && (
                <Option
                    disabled={!props.currentState.link}
                    value='ordered-list-item'
                    className={classNames(unlink.className)}
                    onClick={removeLink}
                    title={unlink.title || translations['components.controls.link.unlink']}
                >
                    <img src={unlink.icon} alt='' />
                </Option>
            )}
            {expanded && showModal ? renderAddLinkModal() : undefined}
        </div>
    );
};

export default LinkLayout;
