import React, { useEffect, HTMLProps } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataActions, AppState } from 'reducers';
import { Fade, Alert } from 'react-bootstrap';
import './NotificationComponent.scss';

const NotificationComponent = (props: HTMLProps<typeof Fade>) => {
    const isVisible = useSelector((state: AppState) => state.data.notificationComponent.props.isVisible);
    const message = useSelector((state: AppState) => state.data.notificationComponent.notificationMessage);
    const variant = useSelector((state: AppState) => state.data.notificationComponent.notificationVariant);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                dispatch(dataActions.hideNotificationComponent());
            }, 3000);
        }
    }, [isVisible]);

    return (
        <Fade in={isVisible}>
            <Alert variant={variant != null ? variant : 'success'} transition={false} style={props.style} className={['NotificationComponent', 'text-center', props.className].filter((s) => s).join(' ')}>
                <Alert.Heading className='NotificationComponentAlert'>{message}</Alert.Heading>
            </Alert>
        </Fade>
    );
};

export const useNotification = () => {
    const dispatch = useDispatch();

    return (message: string, variant: string = 'success') => {
        dispatch(dataActions.setNotificationComponent({
            isVisible: true,
            message,
            variant
        }));
    };
};

export default NotificationComponent;
