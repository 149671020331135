import React, { useState, useEffect } from 'react';
import { Table, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import '../Promotion.scss';
import './PromotionOrders.scss';
import Collapse from 'react-bootstrap/Collapse';
import { SM, LG } from 'lib/util';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import Spinner from 'components/Spinner';
import _ from 'lodash';
import { getPromotionOrders } from 'lib/communication/promotionalProgram';
import Pagination from 'components/Pagination';
import SortButtons from 'components/SortButtons';
import { OrderDirection } from 'lib/types';
import { SortButtonsValue } from 'components/SortButtons/SortButtons';

const PAGE_SIZE = 10;

const PromotionOrders = () => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const isWide = useMediaQuery({ minWidth: SM - 70 });

    const { page } = useParams<{ page: string | undefined }>();
    const history = useHistory();
    const pageNumber = parseInt(page ?? '1') - 1;

    const orders = useSelector((state: AppState) => state.data.promotionalProgram.orders);
    const ordersIsFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
    const [error, setError] = useState(false);
    const [orderBy, setOrderBy] = useState('date');
    const [orderDirection, setOrderDirection] = useState(OrderDirection.Desc);
    const promotion = useSelector((state: AppState) => state.conf.promotion);

    const [opens, setOpens] = useState([false]);

    const size = Math.max(1, _.ceil(_.size(orders) / PAGE_SIZE));
    const sortedOrders = _.orderBy(orders, orderBy, orderDirection);
    const selectedOrders = isDesktop ? _.take(_.slice(sortedOrders, pageNumber * PAGE_SIZE), PAGE_SIZE) : _.take(sortedOrders, (pageNumber + 1) * PAGE_SIZE);

    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
        window.scrollTo(0, scrollPosition);
    }, [scrollPosition]);

    useEffect(() => {
        if (promotion !== '') {
            getPromotionOrders(promotion)
                .then((result) => setError(!result));
        }
    }, [promotion]);

    const handleSortChange = (value: SortButtonsValue) => {
        setOrderBy(value.group);
        setOrderDirection(value.direction);
        setOpens([false]);
    };

    if (ordersIsFetching || error) {
        return <Spinner showError={error} />;
    }

    return (
        <>
            {isDesktop ?
                <>
                    <Row className='justify-content-end'>
                        <Col xs='auto'>
                            <Pagination
                                selected={pageNumber}
                                size={size}
                                href={(p) => `${process.env.PUBLIC_URL}/promotions/orders/${p + 1}`}
                                onSelect={(p) => history.push(`${process.env.PUBLIC_URL}/promotions/orders/${p + 1}`)}
                            />
                        </Col>
                    </Row>
                    <Table borderless striped className='tr3 tr4 tr5 PromotionOrders-table'>
                        <thead>
                            <tr>
                                <th>
                                    Data
                                    <SortButtons
                                        value={{ direction: orderDirection, group: 'date' }}
                                        onChange={handleSortChange}
                                        orderBy={orderBy}
                                    />
                                </th>
                                <th>Produkt</th>
                                <th>
                                    Sztuk
                                    <SortButtons
                                        value={{ direction: orderDirection, group: 'quantity' }}
                                        onChange={handleSortChange}
                                        orderBy={orderBy}
                                    />
                                </th>
                                <th>
                                    Ilość punktów
                                    <SortButtons
                                        value={{ direction: orderDirection, group: 'points' }}
                                        onChange={handleSortChange}
                                        orderBy={orderBy}
                                    />
                                </th>
                                <th>
                                    Cena promocyjna
                                    <SortButtons
                                        value={{ direction: orderDirection, group: 'price' }}
                                        onChange={handleSortChange}
                                        orderBy={orderBy}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedOrders.map((order, index) => (
                                <tr key={`order ${order.date} ${index}`}>
                                    <td>{order.date}</td>
                                    <OverlayTrigger
                                        overlay={<Tooltip id='order_description_tooltip'>{order.description}</Tooltip>}
                                    >
                                        <td className='PromotionOrders-descriptionColumn'>
                                            {order.description}
                                        </td>
                                    </OverlayTrigger>
                                    <td>{order.quantity}</td>
                                    <td>{order.points}</td>
                                    <td>{order.price.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {selectedOrders.length === 0 && <div>Brak zamówień promocyjnych.</div>}
                </> :
                <div>
                    {selectedOrders.map((order, index) => (
                        <div
                            key={index}
                            className={`block-item${opens[index] ? ' rotate' : ''}`}
                            onClick={() => {
                                const arr = opens.splice(0);
                                arr[index] = !arr[index];
                                setOpens(arr);
                            }}
                        >
                            {isWide ? (
                                <>
                                    <div>
                                        <span>Produkt:</span>
                                        <hr />
                                        <b>{order.description}</b>
                                    </div>
                                    <div className='PromotionOrder-block-item' style={{ paddingBottom: opens[index] ? '0rem' : '' }}>
                                        <div className='PromotionOrders-block-item-content-left-up'>
                                            <span>Data:</span>
                                            <hr />
                                            <b>{order.date}</b>
                                        </div>
                                        <div className='PromotionOrders-block-item-content-right-up'>
                                            <span>Sztuk:</span>
                                            <hr />
                                            <b>{order.quantity}</b>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <b>{order.description}</b>
                                    </div>
                                    <div className='PromotionOrder-block-item' style={{ paddingBottom: opens[index] ? '0rem' : '' }}>
                                        <div className='PromotionOrders-block-item-content-left-up'>
                                            <span>Data:</span>
                                            <hr />
                                            <b>{order.date}</b>
                                        </div>
                                        <div className='PromotionOrders-block-item-content-right-up'>
                                            <span>Sztuk:</span>
                                            <hr />
                                            <b>{order.quantity}</b>
                                        </div>
                                    </div>
                                </>
                            )}
                            <Collapse in={opens[index]}>
                                <section>
                                    <div className='PromotionOrder-block-item' style={{ paddingTop: '0rem' }}>
                                        <div className='PromotionOrders-block-item-content-left-down'>
                                            <span>Ilość punktów:</span>
                                            <hr />
                                            <b>{order.points}</b>
                                        </div>
                                        <div className='PromotionOrders-block-item-content-right-down'>
                                            <span>Cena promocyjna:</span>
                                            <hr />
                                            <b>{order.price.toFixed(2)}</b>
                                        </div>
                                    </div>
                                </section>
                            </Collapse>
                        </div>
                    ))}
                    {selectedOrders.length === 0 && <div>Brak zamówień promocyjnych.</div>}
                    {(pageNumber + 1) * PAGE_SIZE > _.size(orders) ? (<></>) : (
                        <Button
                            onClick={() => {
                                setScrollPosition(window.pageYOffset);
                                history.push(`${process.env.PUBLIC_URL}/promotions/orders/${+(page ?? 1) + 1}`);
                            }}
                            style={{ marginTop: 10 }}
                        >
                            Pokaż więcej
                        </Button>
                    )}
                </div>}
        </>
    );
};

export default PromotionOrders;
