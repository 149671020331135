import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Dispatch } from 'redux';

interface ConfigTypes {
    companyId: number | null,
    zamowieniaServiceAddress: string,
    ISODDomain: string,
    ISODURL: string,
    isOnLocalHost: boolean,
    brandColor: string,
    brandLightColor: string,
    brandBrightColor: string,
    tableFontColor: string,
    promotion: string,
    isPlateCreatorOn: boolean,
    GutterCreatorScripts: string[],
    isDebug: boolean,
    logo: string,
    menu: {
        news: boolean,
        orders: boolean,
        new_order: boolean,
        billings: boolean,
        promotion: boolean,
        isod: boolean,
        account: boolean,
        settings: boolean,
    },
    GutterCreatorURL: string,
    GutterCreatorVersion: string,
    lang: string,
    shutdownHours: {
        from: number,
        to: number
    },
    ga4TrackingId: string,
}

interface CompanyType {
    companyId: number | null,
    serverName: string,
    zamowieniaServiceAddress: string,
    companyWebsite: string,
    logo: string,
    ISODDomain: string,
    ISODURL: string,
    brandColor: string,
    brandLightColor: string,
    brandBrightColor: string,
    tableFontColor: string,
    promotion: string,
    menu: {
        news: boolean,
        orders: boolean,
        new_order: boolean,
        billings: boolean,
        promotion: boolean,
        isod: boolean,
        account: boolean,
        settings: boolean,
    },
    isPlateCreatorOn: boolean,
    GutterCreatorScripts: string[],
    GutterCreatorURL: string,
    GutterCreatorVersion: string,
    lang: string
}

interface ConfigNewTypes {
    config: {
        isOnLocalHost: boolean,
        isDebug: boolean,
        shutdownHours: {
            from: number,
            to: number
        },
        ga4TrackingId: string,
    },
    companies: CompanyType[]
}

const fetchConfig = () => fetch(`${process.env.PUBLIC_URL}/config.json`)
    .then((response) => response.json())
    .then((data) => data as ConfigNewTypes);

const confSlice = createSlice({
    name: 'conf',
    initialState: {
        companyId: null,
        zamowieniaServiceAddress: '',
        ISODDomain: '',
        ISODURL: '',
        isOnLocalHost: true,
        brandColor: '',
        brandLightColor: '',
        brandBrightColor: '',
        tableFontColor: '',
        promotion: '',
        isPlateCreatorOn: false,
        GutterCreatorScripts: [],
        isDebug: false,
        logo: '',
        menu: {
            news: false,
            orders: false,
            new_order: false,
            billings: false,
            promotion: false,
            isod: false,
            account: false,
            settings: false
        },
        GutterCreatorURL: '',
        GutterCreatorVersion: '1',
        lang: 'PL',
        shutdownHours: {
            from: 22,
            to: 6
        },
        serviceWorks: [],
        ga4TrackingId: ''
    } as ConfigTypes,
    reducers: {
        setConfigState: (state, action: PayloadAction<ConfigTypes>) => (
            { ...state, ...action.payload }
        )
    }
});

export default confSlice.reducer;

const { setConfigState } = confSlice.actions;

export const setSliceState = (currentURL: string, currentCompany: number | null) => async (dispatch: Dispatch<any>) => {
    const result = await fetchConfig();

    let company = _.filter(result.companies, ({ companyId, serverName }) => (currentCompany === null ? serverName.includes(currentURL) : companyId === currentCompany));

    company = company.length !== 0 ? company : [result.companies[0]];

    const res: ConfigTypes = {
        companyId: company[0].companyId,
        zamowieniaServiceAddress: company[0].zamowieniaServiceAddress,
        ISODDomain: company[0].ISODDomain,
        ISODURL: company[0].ISODURL,
        isOnLocalHost: result.config.isOnLocalHost,
        brandColor: company[0].brandColor,
        brandLightColor: company[0].brandLightColor,
        brandBrightColor: company[0].brandBrightColor,
        tableFontColor: company[0].tableFontColor,
        promotion: company[0].promotion,
        isPlateCreatorOn: company[0].isPlateCreatorOn,
        GutterCreatorScripts: company[0].GutterCreatorScripts,
        isDebug: result.config.isDebug,
        logo: company[0].logo,
        menu: company[0].menu,
        GutterCreatorURL: company[0].GutterCreatorURL,
        GutterCreatorVersion: company[0].GutterCreatorVersion,
        lang: company[0].lang,
        shutdownHours: {
            from: result.config.shutdownHours.from,
            to: result.config.shutdownHours.to
        },
        ga4TrackingId: result.config.ga4TrackingId
    };

    dispatch(setConfigState(res));
};
