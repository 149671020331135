import React from 'react';
import './GutterCreator.scss';
import { AppState } from 'reducers';
import { useSelector } from 'react-redux';
import { Product, GutteringSystemData } from 'lib/types';
import GutterCreatorV2, { ImportedDataV2 } from './GutterCreatorV2';
import GutterCreatorV1, { ImportedDataV1 } from './GutterCreatorV1';

export type ImportedData = ImportedDataV1 | ImportedDataV2;

export interface DataFromGutterings {
    product: Product;
    quantity: number;
    systemData?: GutteringSystemData;
}

export const IDENTIFIER = 'GutterCreator';

const GutterCreator = () => {
    const version = useSelector((state: AppState) => state.conf.GutterCreatorVersion);

    return version === '2' ? <GutterCreatorV2 /> : <GutterCreatorV1 />;
};

export default GutterCreator;
