import React, { useEffect, useLayoutEffect, useState } from 'react';
import store from 'store';
import _ from 'lodash';
import { getImportedProductsInfoMocked } from 'lib/communication/orders';
import { Product } from 'lib/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { createPortal } from 'react-dom';

export interface DataFromGutterings {
    product: Product;
    quantity: number;
}

export const submitPlatesKreator = (message: string): void => {
    const { edit } = store.getState().data.orders;
    let data = JSON.parse(message);
    data = _.map(data, (product) => {
        const optAccessories = _.map(product.accessories, (accessory) => ({ value: accessory.value, unit: accessory.unit, count: accessory.count }));
        return { symKar: product.SymKar, quantity: product.count, dimension: parseFloat(product.length), accessories: optAccessories };
    });
    getImportedProductsInfoMocked(data, edit ? 'addToLocalEditCart' : 'addToLocalCart'); // mock-up funkcji do dodawania jak w rynnach, trzeba będzie zwróćić poprawną ale narazie działmy na niepoprawnych danych-dodaje poprawnie tylko używa zamockupowanych danych podczas dodawania
};

const PlatesCreator = React.memo(() => {
    const dispatch = useDispatch();

    const edit = useSelector((state: AppState) => state.data.orders.edit);
    const localCart = useSelector((state: AppState) => (edit ? state.data.orders.localEditCart : state.data.orders.localCart));

    const [isPlatesDownloaded, setIsPlatesDownloaded] = useState(false);
    const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
    const mountNode = contentRef?.contentWindow;

    useEffect(() => {
        if (mountNode) {
            (mountNode as any).submitPlatesKreator = submitPlatesKreator;
        }
    }, [mountNode]);

    useEffect(() => {
        const loadScripts = async (scriptUrls: string[]) => {
            const load = (scriptUrl: string) => new Promise(((resolve) => {
                const script = mountNode!.document.createElement('script');
                script.onload = resolve;
                script.src = `${process.env.PUBLIC_URL}${scriptUrl}`;
                mountNode!.document.body.appendChild(script);
            }));
            const promises = [];
            promises.push(new Promise(((resolve) => {
                const sheet = document.createElement('link');
                sheet.onload = resolve;
                sheet.rel = 'stylesheet';
                sheet.href = `${process.env.PUBLIC_URL}/kreator_plyt_warstwowych/static/css/main.d1cf572a.chunk.css`;
                sheet.type = 'text/css';
                mountNode!.document.head.appendChild(sheet);
            })));
            scriptUrls.forEach((scriptUrl) => { promises.push(load(scriptUrl)); });
            await Promise.all(promises);
            setIsPlatesDownloaded(true);
        };
        if (!isPlatesDownloaded && mountNode) {
            loadScripts([
                '/kreator_plyt_warstwowych/static/js/2.e5f5d9c7.chunk.js',
                '/kreator_plyt_warstwowych/static/js/main.52504a3c.chunk.js',
                '/kreator_plyt_warstwowych/static/js/runtime-main.4303c0fb.js'
            ]);
        }
    }, [isPlatesDownloaded, dispatch, mountNode]);

    useLayoutEffect(() => {
        if (!isPlatesDownloaded || !mountNode || !contentRef) return;
        const platesProducts = localCart.productGroups.map((g) => {
            if (g.groupPath.includes('plyty')) return g.mainProducts;
            return [];
        });
        mountNode.platesMount(
            'plates_creator',
            JSON.stringify(platesProducts)
        );
        return () => {
            mountNode.platesUnmount('plates_creator');
        };
    }, [isPlatesDownloaded, localCart, mountNode, contentRef]);

    return (
        <iframe title='kreator_plyt_warstwowych' width='100%' height='1070px' ref={setContentRef}>
            {mountNode && createPortal(<div id='plates_creator'></div>, mountNode.document.body)}
        </iframe>
    );
});

declare global {
    interface Window {
        platesMount(id: string, order: string): void;
        platesUnmount(id: string): void;
    }
}

export default PlatesCreator;
