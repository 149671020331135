import React from 'react';
import { Modal } from 'react-bootstrap';
import { requestsActions, AppState } from 'reducers';
import { useSelector } from 'react-redux';

import store from 'store';

const RequestErrorModal = () => {
    const show = useSelector((state: AppState) => state.requests.responseError);
    const errorMes = useSelector((state: AppState) => state.requests.errorMessage);

    return (
        <Modal
            show={show}
            onHide={() => { store.dispatch(requestsActions.setResponseError(false)); }}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Wystąpił problem</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ textAlign: 'center' }}>{errorMes}</div>
            </Modal.Body>
        </Modal>
    );
};

export default RequestErrorModal;
