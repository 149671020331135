import React, { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import './ImageThumbnail.scss';

// there is some issue with the type of 'key'
// it's not needed anyway
type ImageProps = Omit<React.ComponentPropsWithoutRef<typeof Image>, 'key'>;

const ImageThumbnail = (props: ImageProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <>
            <Image {...props} onClick={() => setModalVisible(true)} thumbnail className='ImageThumbnail-thumbnail' />
            <Modal show={modalVisible} onHide={() => setModalVisible(false)} centered size='lg'>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Image {...props} className='ImageThumbnail-modal-img' />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImageThumbnail;
