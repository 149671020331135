import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AppState, useUser } from 'reducers';

import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';
import ChangePassword from 'containers/Account/ChangePassword';

const LoginContainer = () => {
    const logo = useSelector((state: AppState) => state.conf.logo);
    const company = useSelector((state: AppState) => state.conf.companyId);

    const user = useUser();

    return (
        <>
            {user && user.forcePwdChange && <Redirect to={`${process.env.PUBLIC_URL}/changePassword`} />}
            <div className='Login'>
                <div className='Login-header'>
                    {company !== null && <img src={`${process.env.PUBLIC_URL}/${logo}`} alt='Blachy Pruszyński' className='my-5' />}
                    <h1 className='mb-4'>System obsługi zleceń online</h1>
                </div>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL}/resetPassword`} exact>
                        <ResetPassword />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/changePassword`} exact>
                        <ChangePassword />
                    </Route>
                    <Route path='/'>
                        <LoginForm />
                    </Route>
                </Switch>
            </div>
        </>
    );
};

export default LoginContainer;
