import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState, useUser } from 'reducers';
import { ISODPackage, ISODPackageData } from 'lib/types';
import { resetIsodCart, loadSessionCart } from 'lib/communication/orders';

export const ISOD_STORAGE_KEY = 'ISODProducts';

const ISOD = (prop: { visibility: string }) => {
    const ISODURL = useSelector((state: AppState) => state.conf.ISODURL);
    const ISODDomain = useSelector((state: AppState) => state.conf.ISODDomain);
    const ISODFrame = useRef<HTMLIFrameElement>(null);
    const isodCart = useSelector((state: AppState) => state.data.orders.localCart.isod);
    const history = useHistory();

    const hideISOD = () => {
        history.goBack();
    };
    (window as any).hideISOD = hideISOD;

    const user = useUser();

    const handleProducts = (data: ISODPackage) => {
        const products = data.data as ISODPackageData;
        const storage = window.sessionStorage.getItem(ISOD_STORAGE_KEY);
        if (storage) {
            const cart = JSON.parse(storage) as ISODPackageData[];
            products.scalKoszyk = cart[0].scalKoszyk;
            cart.push(products);
            window.sessionStorage.setItem(ISOD_STORAGE_KEY, JSON.stringify(cart));
        } else {
            window.sessionStorage.setItem(ISOD_STORAGE_KEY, JSON.stringify([products]));
        }
    };

    const sendToISOD = useCallback((data: ISODPackage) => {
        ISODFrame.current?.contentWindow?.postMessage(JSON.stringify(data), ISODDomain);
    }, [ISODDomain]);

    const messageHandler = useCallback((event: MessageEvent) => {
        if (event.origin !== ISODDomain) {
            return;
        }

        const data = JSON.parse(event.data) as ISODPackage;
        const { action } = data;
        switch (action) {
            case 'ISODClosed':
                history.goBack();
                break;

            case 'ISODPoll':
                sendToISOD({ action: 'ISODPoll', data: user ?? {} });
                break;

            case 'ISODSendProducts':
                if (isodCart.length > 0 && !(ISOD_STORAGE_KEY in window.sessionStorage)) {
                    sendToISOD({ action: 'ISODSendProducts', data: false });
                } else {
                    handleProducts(data);
                    sendToISOD({ action: 'ISODSendProducts', data: true });
                }
                break;

            case 'ISODReplaceProducts':
                resetIsodCart();
                handleProducts(data);
                sendToISOD({ action: 'ISODReplaceProducts', data: true });
                break;

            case 'ISODMergeProducts':
                handleProducts(data);
                sendToISOD({ action: 'ISODMergeProducts', data: true });
                break;

            default:
                break;
        }
    }, [ISODDomain, history, isodCart, sendToISOD]);

    useEffect(() => {
        window.addEventListener('message', messageHandler, false);

        return () => { window.removeEventListener('message', messageHandler, false); };
    }, [ISODDomain, messageHandler]);

    // only called on component mount
    useEffect(() => {
        loadSessionCart();
    }, []);

    return (
        <iframe
            id='ISOD'
            title='ISOD'
            ref={ISODFrame}
            src={`${ISODURL}?company=${user?.companyIsod}`}
            width='100%'
            height='100%'
            style={{
                display: prop.visibility,
                position: 'fixed',
                border: '0',
                top: '0',
                right: '0',
                bottom: '0',
                left: '0'
            }}
        />
    );
};

export default ISOD;
