import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Collapse, Form, Table } from 'react-bootstrap';
import { AppState, dataActions } from 'reducers';
import { FaCheck, FaTrashAlt, FaEdit, FaAngleUp, FaAngleDown } from 'react-icons/fa';
import store from 'store';

import { Product, ProductInCart, Unit } from 'lib/types';
import { MdClose } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import AdditionalProducts from './AdditionalProducts';
import './ProductSelection.scss';
import NumberInput from 'components/NumberInput';
import { showCartMessage } from '../AssortmentSelection';

interface SimilarProductsProps {
    selectedProduct: Product;
}

const MAX_SIMILAR_COUNT_SHOW = 6;

const SimilarProducts = ({ selectedProduct }: SimilarProductsProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });

    const edit = useSelector((state: AppState) => state.data.orders.edit);
    const cartContent = useSelector((state: AppState) => (edit ? state.data.orders.editCart : state.data.orders.cart));

    const [similarEdit, setSimilarEdit] = useState(null as number | null);
    const [similarEditQuantity, setSimilarEditQuantity] = useState(1);
    const [similarEditDimension, setSimilarEditDimension] = useState(1);
    const [similarEditIsDimension, setSimilarEditIsDimension] = useState(false);
    const [similarAdditions, setSimilarAdditions] = useState(Array<boolean>());
    const [isSavable, setSavable] = useState(similarEditQuantity > 0 && similarEditDimension > 0);
    const [collapseSimilar, setCollapseSimilar] = useState(false);
    const [showMoreSimilar, setShowMoreSimilar] = useState(false);
    const [similarProducts, setSimilarProducts] = useState<ProductInCart[]>(Array<ProductInCart>());
    const [similarEditUnit, setSimilarEditUnit] = useState<Unit>({ unit: 'szt', isDefault: false, converter: 1, description: 'sztuki' });

    const similarProductsRef = useRef<null | HTMLDivElement>(null);

    const makeGroupName = () => _.join(selectedProduct.path, ' ');

    const similarProductCount = _.concat(
        _.find(cartContent.productGroups, (group) => group.groupName === makeGroupName())?.mainProducts.products,
        _.find(cartContent.productGroups, (group) => group.groupName === makeGroupName())?.additionalProducts.products
    ).length;
    const [collapseSimilarDetails, setCollapseSimilarDetails] = useState(Array<boolean>(similarProductCount).fill(false));

    const productGroup = _.find(cartContent.productGroups, (group) => group.groupName === makeGroupName());

    const totalSimilarQuantity = +(similarEditQuantity * (similarEditIsDimension ? similarEditDimension : 1)).toFixed(2);
    const multiplyByTotalSimilarQuantity = (value: number): number => +(totalSimilarQuantity * value * similarEditUnit.converter).toFixed(2);

    const deleteProduct = (index: number) => {
        if (isDesktop) showCartMessage();

        if (!productGroup) return;
        store.dispatch(
            dataActions[edit ? 'deleteFromLocalEditCart' : 'deleteFromLocalCart'](
                {
                    groupPath: makeGroupName(),
                    index: productGroup.mainProducts.products.length > index ? index : index - productGroup.mainProducts.products.length,
                    productsType: productGroup.mainProducts.products.length > index ? 'mainProducts' : 'additionalProducts',
                    symKar: 'symKar'
                }
            )
        );
        setSimilarEdit(null);
    };

    const editProduct = (index: number, productInCart: ProductInCart) => {
        setSimilarEdit(index);
        setSimilarEditQuantity(productInCart.quantity);
        setSimilarEditDimension(productInCart.dimension ?? 1);
        setSimilarEditIsDimension(productInCart.product.isDimension);
        setSimilarAdditions(productInCart.additionsSelected ?? []);
        setSimilarEditUnit(productInCart.unit);
    };

    const saveProduct = (index: number, productInCart: ProductInCart) => {
        if (!productGroup) return;
        if (!isSavable) return;
        setSimilarEdit(null);
        store.dispatch(
            dataActions[edit ? 'editProductsInLocalEditCart' : 'editProductsInLocalCart'](
                {
                    groupPath: makeGroupName(),
                    productsType: productGroup.mainProducts.products.length > index ? 'mainProducts' : 'additionalProducts',
                    index: productGroup.mainProducts.products.length > index ? index : index - productGroup.mainProducts.products.length,
                    product: {
                        additionsSelected: similarAdditions,
                        dimension: similarEditDimension,
                        quantity: similarEditQuantity,
                        product: productInCart.product,
                        mainProductSymkar: productInCart.mainProductSymkar,
                        unit: productInCart.unit
                    }
                }
            )
        );
    };

    const handleScrollTo = () => {
        if (similarProductsRef && similarProductsRef.current) similarProductsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const getSimilarProductRow = (productInCart: ProductInCart, index: number) => {
        const totalQuantity = +(productInCart.quantity * (productInCart.product.isDimension ? productInCart.dimension ?? 1 : 1)).toFixed(2);
        return (
            <tr key={`similar_${index}`}>
                <td>{productInCart.product.description}</td>
                <td className={similarEdit === index && productInCart.dimension ? 'edit-td' : ''} style={{ padding: '0px 1px' }}>
                    {
                        similarEdit === index ? (
                            <NumberInput
                                value={similarEditQuantity}
                                setValue={(value) => setSimilarEditQuantity(value)}
                                disabledEdit={similarEditQuantity === 1}
                            />
                        ) : productInCart.quantity
                    }
                </td>
                <td className={similarEdit === index && productInCart.dimension ? 'edit-td' : ''} style={{ padding: '0px 1px' }}>
                    {
                        similarEdit === index && productInCart.dimension ? (
                            <NumberInput
                                value={similarEditDimension}
                                setValue={(value) => setSimilarEditDimension(value)}
                                step={0.1}
                                min={selectedProduct.dimensionMin ?? 0.01}
                                max={selectedProduct.dimensionMax ?? 10 ** 5}
                                fractDigits={3}
                                disabled={_.isEqual(productInCart.product.unit, 'szt') || !productInCart.product.dimensionCanEdit}
                                disabledEdit={similarEditDimension === 0.1}
                            />
                        ) : productInCart.dimension?.toFixed(3)
                    }
                </td>
                {
                    _.map(selectedProduct.additions, (addition, addIndex) => (
                        <td className={similarEdit === index && productInCart.dimension ? 'edit-td' : ''} key={`cartAdditionOnProd${addIndex}`}>
                            {
                                _.some(productInCart.product.additions, (add) => add.name === addition.name) && productInCart.additionsSelected ? (
                                    similarEdit === index ? (
                                        <Form.Check
                                            name={addition.name}
                                            type='checkbox'
                                            defaultChecked={productInCart.additionsSelected[addIndex]}
                                            disabled={productInCart.product.additions[addIndex].default}
                                            onChange={() => {
                                                const newArr = [...similarAdditions];
                                                newArr[addIndex] = !similarAdditions[addIndex];
                                                setSimilarAdditions(newArr);
                                                setSavable(similarEditQuantity > 0 && similarEditDimension > 0);
                                            }}
                                        />
                                    ) : productInCart.additionsSelected[addIndex] ? <FaCheck /> : <MdClose />
                                ) : 'N/A'
                            }
                        </td>
                    ))
                }
                <td>{productInCart.unit.unit}</td>
                <td>
                    {((productInCart.additionsSelected && productInCart.additionsSelected[0] ?
                        productInCart.product.priceInfo.priceWithFoilAfterDiscount : productInCart.product.priceInfo.priceAfterDiscount) *
                        productInCart.unit.converter).toFixed(2)}
                </td>
                <td>{similarEdit === index ? totalSimilarQuantity : totalQuantity}</td>
                <td>{+((similarEdit === index ? totalSimilarQuantity : totalQuantity) * productInCart.product.priceInfo.converterM2).toFixed(2)}</td>
                <td>
                    <div className='price-td-div' style={{ float: 'right', paddingRight: '1.5rem' }}>
                        {similarEdit === index ?
                            multiplyByTotalSimilarQuantity(
                                similarAdditions && similarAdditions[0] ?
                                    productInCart.product.priceInfo.grossWithFoil : productInCart.product.priceInfo.gross
                            ) : (productInCart.unit.converter * totalQuantity * (
                                productInCart.additionsSelected && productInCart.additionsSelected[0] ?
                                    productInCart.product.priceInfo.grossWithFoil : productInCart.product.priceInfo.gross
                            )).toFixed(2)}
                    </div>
                </td>
                <td>
                    {
                        similarEdit === index ?
                            <FaCheck className={`manage-cart-icon fa-check ${!isSavable ? 'disabled-icon' : ''}`} onClick={() => saveProduct(index, productInCart)} /> :
                            <FaEdit className='manage-cart-icon' onClick={() => editProduct(index, productInCart)} />
                    }
                </td>
                <td>
                    <FaTrashAlt className='manage-cart-icon' onClick={() => deleteProduct(index)} />
                </td>
            </tr>
        );
    };

    useEffect(() => {
        setCollapseSimilarDetails(Array<boolean>(similarProductCount).fill(false));
    }, [similarProductCount]);

    useEffect(() => {
        setSavable(similarEditQuantity > 0 && similarEditDimension > 0);
    }, [similarEditQuantity, similarEditDimension]);

    useEffect(() => {
        setSimilarProducts(_.concat(productGroup?.mainProducts.products ?? [], productGroup?.additionalProducts.products ?? []).reverse());
    }, [cartContent]);

    if (!productGroup) {
        return null;
    }

    const getSimilarProductsHeader = () => (
        <tr>
            <th style={{ borderRadius: '4px 0 0 0' }} className='ProductSelection-similarProductsTable-description ProductSelection-similarProductsTable-field'>Nazwa</th>
            <th className='ProductSelection-similarProductsTable-quantity ProductSelection-similarProductsTable-field'>Ilość [szt.]</th>
            <th className='ProductSelection-similarProductsTable-dimension ProductSelection-similarProductsTable-field'>Wymiar</th>
            {
                _.map(_.concat(selectedProduct.additions), (addition) => (
                    <th key={`addition${addition.name}`} className='ProductSelection-similarProductsTable-additions ProductSelection-similarProductsTable-field'>{addition.name}</th>
                ))
            }
            <th className='ProductSelection-similarProductsTable-measurement-unit ProductSelection-similarProductsTable-field'>JM</th>
            <th className='ProductSelection-similarProductsTable-gross-price-unit ProductSelection-similarProductsTable-field'>
                Cena
                <br />
                za JM
            </th>
            <th className='ProductSelection-similarProductsTable-measurement-unit-quantity ProductSelection-similarProductsTable-field'>
                Ilość
                <br />
                w JM
            </th>
            <th className='ProductSelection-similarProductsTable-square-meters-quantity ProductSelection-similarProductsTable-field'>
                ILOŚĆ
                <br />
                w m
                <sup>2</sup>
            </th>
            <th className='ProductSelection-similarProductsTable-gross-price ProductSelection-similarProductsTable-field'>BRUTTO</th>
            <th className='ProductSelection-similarProductsTable-icon' />
            <th style={{ borderRadius: '0 4px 0 0' }} className='ProductSelection-similarProductsTable-icon' />
        </tr>
    );

    return isDesktop ? (
        <div className='AssortmentSelection-similarProductsTable-container'>
            <h4 style={{ marginBottom: '4px' }}>Podobne produkty znajdujące się w koszyku</h4>
            <Table size='sm' borderless striped className='ProductSelection-similarProductsTable'>
                <thead>
                    {
                        getSimilarProductsHeader()
                    }
                </thead>
                <tbody>
                    {_.map(_.slice(similarProducts, 0, MAX_SIMILAR_COUNT_SHOW),
                        (productInCart, index) => getSimilarProductRow(productInCart, similarProducts.length - index - 1))}
                </tbody>
            </Table>
            <Collapse in={showMoreSimilar}>
                <div>
                    <Table size='sm' borderless striped className='ProductSelection-similarProductsTable unselectable'>
                        <thead style={{ visibility: 'collapse' }}>
                            {
                                getSimilarProductsHeader()
                            }
                        </thead>
                        <tbody>
                            {
                                _.map(_.slice(similarProducts, MAX_SIMILAR_COUNT_SHOW), (productInCart, index) => getSimilarProductRow(productInCart, (similarProducts.length - MAX_SIMILAR_COUNT_SHOW - index - 1)))
                            }
                        </tbody>
                    </Table>
                </div>
            </Collapse>
            {
                similarProducts.length > MAX_SIMILAR_COUNT_SHOW && (
                    <div className='SimilarProducts-collapse-arrow-box' onClick={() => setShowMoreSimilar(!showMoreSimilar)}>
                        {showMoreSimilar ? <FaAngleUp size='35px' /> : <FaAngleDown size='35px' />}
                    </div>
                )
            }

        </div>
    ) : (
        <div className='ProductSelection-collapseSimilar' ref={similarProductsRef}>
            <div className='ProductSelection-addProductHeader' onClick={() => setCollapseSimilar(!collapseSimilar)}>
                <b>Podobne produkty w koszyku</b>
                {collapseSimilar ? <FaAngleUp size='40px' /> : <FaAngleDown size='40px' />}
            </div>
            <Collapse in={collapseSimilar} onEntered={handleScrollTo}>
                <div>
                    {_.map(_.concat(productGroup?.mainProducts.products ?? [], productGroup?.additionalProducts.products ?? []),
                        (productInCart, index) => {
                            const totalQuantity = +(productInCart.quantity * (productInCart.dimension ?? 1)).toFixed(2);
                            return (
                                <section
                                    key={`similar_${index}`}
                                    className={`block-item ${collapseSimilarDetails[index] ? 'rotate' : ''}`}
                                    onClick={() => {
                                        const newArr = [...collapseSimilarDetails];
                                        newArr[index] = !newArr[index];
                                        setCollapseSimilarDetails(newArr);
                                    }}
                                >
                                    <div>
                                        <div className='ProductSelection-SimilarProducts-headerData'>
                                            <b>
                                                {productInCart.product.description}
                                                {' '}
                                                <br />
                                            </b>
                                        </div>
                                        <div className='ProductSelection-SimilarProducts-headerData'>
                                            <div>
                                                Ilość sztuk:
                                                {' '}
                                                <b>
                                                    {
                                                        similarEdit === index ? (
                                                            <NumberInput
                                                                value={similarEditQuantity}
                                                                setValue={(value) => setSimilarEditQuantity(value)}
                                                                stopPropagation
                                                                disabledEdit={similarEditQuantity === 1}
                                                            />
                                                        ) : productInCart.quantity
                                                    }
                                                </b>
                                            </div>
                                            <div>
                                                Wymiar:
                                                {' '}
                                                <b>
                                                    {
                                                        similarEdit === index && productInCart.dimension ? (
                                                            <NumberInput
                                                                value={similarEditDimension}
                                                                setValue={(value) => setSimilarEditDimension(value)}
                                                                step={0.1}
                                                                min={selectedProduct.dimensionMin ?? 0.01}
                                                                max={selectedProduct.dimensionMax ?? 10 ** 5}
                                                                fractDigits={3}
                                                                stopPropagation
                                                                disabled={_.isEqual(selectedProduct.unit, 'szt') || !productInCart.product.dimensionCanEdit}
                                                                disabledEdit={similarEditDimension === 0.01}
                                                            />
                                                        ) : (productInCart.dimension ?? 1).toFixed(3)
                                                    }
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                    <Collapse in={collapseSimilarDetails[index]}>
                                        <section>
                                            {
                                                _.map(selectedProduct.additions, (addition, addIndex) => (
                                                    <AdditionalProducts
                                                        addIndex={addIndex}
                                                        addition={addition}
                                                        index={index}
                                                        product={productInCart}
                                                        similarEdit={similarEdit}
                                                        change={() => {
                                                            const newArr = [...similarAdditions];
                                                            newArr[addIndex] = !similarAdditions[addIndex];
                                                            setSimilarAdditions(newArr);
                                                        }}
                                                    />
                                                ))
                                            }
                                            <div>
                                                <span>JM: </span>
                                                <hr />
                                                <b>{productInCart.product.unit}</b>
                                            </div>
                                            <div>
                                                <span>Cena za JM: </span>
                                                <hr />
                                                <b>
                                                    {(productInCart.additionsSelected && productInCart.additionsSelected[0] ?
                                                        productInCart.product.priceInfo.priceWithFoilAfterDiscount : productInCart.product.priceInfo.priceAfterDiscount).toFixed(2)}
                                                </b>
                                            </div>
                                            <div>
                                                <span>Ilość w JM: </span>
                                                <hr />
                                                <b>{similarEdit === index ? totalSimilarQuantity : totalQuantity}</b>
                                            </div>
                                            <div>
                                                <span>Ilość w M^2: </span>
                                                <hr />
                                                <b>{+((similarEdit === index ? totalSimilarQuantity : totalQuantity) * productInCart.product.priceInfo.converterM2).toFixed(2)}</b>
                                            </div>
                                            <div>
                                                <span>Brutto: </span>
                                                <hr />
                                                <b>
                                                    {similarEdit === index ?
                                                        multiplyByTotalSimilarQuantity(
                                                            similarAdditions && similarAdditions[0] ?
                                                                productInCart.product.priceInfo.grossWithFoil : productInCart.product.priceInfo.gross
                                                        ) : +(totalQuantity * (
                                                            productInCart.additionsSelected && productInCart.additionsSelected[0] ?
                                                                productInCart.product.priceInfo.grossWithFoil : productInCart.product.priceInfo.gross
                                                        )).toFixed(2)}
                                                </b>
                                            </div>
                                            <div>
                                                {
                                                    similarEdit === index ?
                                                        <FaCheck
                                                            className={`manage-cart-icon fa-check ${isSavable ? 'active-icon' : 'disabled-icon'}`}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                saveProduct(index, productInCart);
                                                            }}
                                                        /> :
                                                        <FaEdit
                                                            className='manage-cart-icon'
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                editProduct(index, productInCart);
                                                            }}
                                                        />
                                                }
                                                <FaTrashAlt className='manage-cart-icon' onClick={() => deleteProduct(index)} />
                                            </div>
                                        </section>
                                    </Collapse>
                                </section>
                            );
                        })}
                </div>
            </Collapse>
        </div>
    );
};

export default SimilarProducts;
