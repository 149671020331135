import React, { useEffect, useState } from 'react';
import { Form, Col, Modal } from 'react-bootstrap';
import { News as NewsRecord } from 'lib';
import { uploadFile } from 'lib/communication/news';
import './Editor.scss';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ImageDraft from 'components/editor/ImageDraft';
import ImageLayout from 'components/editor/ImageLayout';
import htmlToDraft from 'html-to-draftjs';
import { DATE_ERROR_END, DATE_ERROR_START } from 'lib/util';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LinkLayout from 'components/editor/LinkLayout';
import ButtonComponent from 'components/ButtonComponent';

interface NewsEditorProps {
    news?: NewsRecord;
    onSubmit: (value: NewsRecord) => void;
    onCancel: () => void;
}

const NewsEditor = ({ news, onSubmit, onCancel }: NewsEditorProps) => {
    const [newsTitle, setNewsTitle] = useState(news?.title || '');
    const [titleIsInvalid, setTitleIsInvalid] = useState(false);
    const [dateStart, setDateStart] = useState(news?.dateStart || new Date().toISOString());
    const [dateEnd, setDateEnd] = useState(news?.dateEnd || '');
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [dateStartIsInvalid, setDateStartIsInvalid] = useState(false);
    const [dateEndIsInvalid, setDateEndIsInvalid] = useState(false);
    const [links, setLinks] = useState(news?.links || []);
    const [image, setImage] = useState({
        entityKey: '',
        contentState: new ContentState()
    });
    const [displayPopup, setDisplayPopup] = useState(false);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [editorState, setEditorState] = useState(() => {
        const draft = htmlToDraft(
            news?.content ?? '',
            (nodeName, rawNode) => {
                if (nodeName === 'img') {
                    const node = rawNode as HTMLImageElement;
                    return {
                        type: 'IMAGE',
                        mutability: 'IMMUTABLE',
                        data: {
                            width: node.style.width || node.width || 'auto',
                            height: node.style.height || node.height || 'auto',
                            src: node.getAttribute('src'),
                            href: node.dataset.href
                        }
                    };
                }
            }
        );
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                draft.contentBlocks,
                draft.entityMap
            )
        );
    });

    useEffect(() => {
        if (image.entityKey !== '') {
            setWidth(image.contentState.getEntity(image.entityKey).getData().width);
            setHeight(image.contentState.getEntity(image.entityKey).getData().height);
            setDisplayPopup(true);
        }
    }, [image]);

    const dateValidate = (dateStartIsInvalidValue: React.SetStateAction<boolean>, dateEndIsInvalidValue: React.SetStateAction<boolean>) => {
        setDateStartIsInvalid(dateStartIsInvalidValue);
        setDateEndIsInvalid(dateEndIsInvalidValue);
    };

    const inputCheck = () => {
        let ok = false;

        if (!newsTitle || newsTitle.trim() === '') {
            setTitleIsInvalid(true);
        } else {
            setTitleIsInvalid(false);
        }
        if (!dateStart || dateStart === '') {
            setDateErrorMessage(DATE_ERROR_START);
            setDateStartIsInvalid(true);
        } else if (dateEnd && dateEnd !== '' && dateStart >= dateEnd) {
            setDateErrorMessage(DATE_ERROR_END);
            setDateEndIsInvalid(true);
        } else {
            ok = true;
        }

        if (ok) {
            setDateErrorMessage('');
            dateValidate(false, false);
        }
    };

    const linkFunction = ({ title, target, targetOption }: { title: string, target: string, targetOption: { linkTargetOption: string, file?: File } }) => {
        if (targetOption.file !== undefined) {
            const filename = `${Date.now()}_${targetOption.file.name}`;
            const newFile = new File([targetOption.file], `${Date.now()}_${targetOption.file.name}`);
            uploadFile(newFile);
            target = `/storage/${filename}`;
        }
        setLinks((state) => [...state, target]);
        return ({ title, target, targetOption: targetOption.linkTargetOption });
    };

    return (
        <>
            <Modal show={displayPopup} onHide={() => setDisplayPopup(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Zmień rozmiar obrazka</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        <div className='rdw-image-modal-size'>
                            &#8597;&nbsp;
                            <input
                                onChange={(event) => setHeight(event.target.value)}
                                onBlur={(event) => setHeight(event.target.value)}
                                value={height}
                                name='height'
                                className='rdw-image-modal-size-input'
                                placeholder='Height'
                            />
                            &nbsp;&#8596;&nbsp;
                            <input
                                onChange={(event) => setWidth(event.target.value)}
                                onBlur={(event) => setWidth(event.target.value)}
                                value={width}
                                name='width'
                                className='rdw-image-modal-size-input'
                                placeholder='Width'
                            />
                        </div>
                    </div>
                    <div className='NewOrder-summaryCancelConfirm-buttons'>
                        <ButtonComponent
                            marginLeft='0'
                            marginRight='0'
                            width='45%'
                            text='OK'
                            onClick={() => {
                                image.contentState.mergeEntityData(
                                    image.entityKey,
                                    { width }
                                );
                                image.contentState.mergeEntityData(
                                    image.entityKey,
                                    { height }
                                );
                                EditorState.push(editorState, image.contentState, 'change-block-data');
                                setDisplayPopup(false);
                            }}
                        />
                        <ButtonComponent
                            marginLeft='10%'
                            marginRight='0'
                            width='45%'
                            text='Anuluj'
                            onClick={() => { setDisplayPopup(false); }}
                        />
                    </div>
                </Modal.Body>
            </Modal>
            <Form
                onSubmit={(evt: React.FormEvent<HTMLFormElement>) => {
                    evt.preventDefault();
                    if (dateStartIsInvalid || dateEndIsInvalid || titleIsInvalid) { return; }
                    const content = draftToHtml(
                        convertToRaw(editorState.getCurrentContent()),
                        undefined,
                        undefined,
                        (args) => {
                            if (args.type !== 'IMAGE') return;
                            const { href, src, height: h, width: w, alt } = args.data;
                            if (!href) {
                                return `<div><img src='${src}' alt='${alt || ''}' height='${(!h || Number.isNaN(parseInt(h))) ? 'auto' : h}' width='${(!w || Number.isNaN(parseInt(w))) ? 'auto' : w}' /></div>`;
                            }
                            return `<div><a href='${href}' target='_blank'><img src='${src}' data-href='${href}' alt='${alt || ''}' height='${(!h || Number.isNaN(parseInt(h))) ? 'auto' : h}' width='${(!w || Number.isNaN(parseInt(w))) ? 'auto' : w}' /></a></div>`;
                        }
                    );
                    onSubmit({
                        id: news?.id,
                        title: newsTitle,
                        dateStart,
                        dateEnd,
                        content,
                        links,
                        isArchived: news?.isArchived ?? false,
                        time: news?.time
                    });
                }}
                id='NewsEditor-form'
                className='mx-3'
                noValidate
            >
                <Form.Group controlId='NewsEditor-title'>
                    <Form.Label>Tytuł</Form.Label>
                    <Form.Control
                        type='text'
                        value={newsTitle}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const val = evt.target.value;
                            setNewsTitle(val);
                        }}
                        isInvalid={titleIsInvalid}
                    />
                    <Form.Control.Feedback type='invalid'> Tytuł nie może być pusty. </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='NewsEditor-date' className='NewsEditor-date'>
                    <Form.Row className='NewsEditor-dateRow'>
                        <Form.Group as={Col} className='NewsEditor-dateStart NewsEditor-date'>
                            <Form.Label htmlFor='from'>Data publikacji</Form.Label>
                            <DatePicker
                                id='from'
                                locale='pl'
                                selected={dateStart ? new Date(dateStart) : null}
                                onChange={(date) => { if (date !== null) { (setDateStart(date.toISOString().split('T')[0])); } }}
                                placeholderText='rrrr-mm-dd'
                                dateFormat='yyyy-MM-dd'
                                className={dateStartIsInvalid ? 'NewsEditor-date-picker-error' : 'NewsEditor-date-picker'}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className='NewsEditor-dateEnd NewsEditor-date'>
                            <Form.Label htmlFor='to'>Data zakończenia wyświetlania</Form.Label>
                            <DatePicker
                                id='to'
                                locale='pl'
                                selected={dateEnd ? new Date(dateEnd) : null}
                                onChange={(date) => { if (date !== null) { (setDateEnd(date.toISOString().split('T')[0])); } else { setDateEnd(''); } }}
                                placeholderText='rrrr-mm-dd'
                                dateFormat='yyyy-MM-dd'
                                className={dateEndIsInvalid ? 'NewsEditor-date-picker-error' : 'NewsEditor-date-picker'}
                            />
                        </Form.Group>
                    </Form.Row>
                    {(dateStartIsInvalid || dateEndIsInvalid) && (
                        <span className='dateError-message'>
                            {' '}
                            {dateErrorMessage}
                            {' '}
                        </span>
                    )}
                </Form.Group>
                <Form.Group controlId='NewsEditor-content' className='NewsEditor-content'>
                    <div style={{ marginBottom: '0.5rem' }}>
                        <span>Treść</span>
                    </div>
                    <DraftEditor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        customBlockRenderFunc={(contentBlock) => {
                            const type = contentBlock.getType();
                            const component = {
                                component: ImageDraft,
                                editable: false,
                                props: {
                                    setImage,
                                    config: {
                                        onChange: setEditorState,
                                        editorState
                                    },
                                    uploadFile: (file: any) => {
                                        const newFile = new File([file], `${Date.now()}_${file.name}`);
                                        return new Promise((res, reg) => {
                                            uploadFile(newFile).then((e) => {
                                                setLinks((state) => [...state, e]);
                                                res(e);
                                            }, (error) => {
                                                alert(error);
                                                reg(error);
                                            });
                                        });
                                    }
                                }
                            };
                            if (type === 'atomic') {
                                return component;
                            } if (contentBlock.getEntityAt(0)) {
                                const contentState = editorState.getCurrentContent();
                                const entity = contentState.getEntity(contentBlock.getEntityAt(0));
                                if (entity && entity.getType() === 'IMAGE') {
                                    return component;
                                }
                            }
                        }}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'link', 'image'],
                            image: {
                                component: ImageLayout,
                                uploadEnabled: true,
                                previewImage: true,
                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                alt: { present: true, mandatory: false },
                                defaultSize: {
                                    height: 'auto',
                                    width: '600'
                                },
                                uploadCallback: (file: any) => {
                                    const newFile = new File([file], `${Date.now()}_${file.name}`);
                                    return new Promise((res, reg) => {
                                        uploadFile(newFile).then((e) => {
                                            setLinks((state) => [...state, e]);
                                            res(e);
                                        }, (error) => {
                                            alert(error);
                                            reg(error);
                                        });
                                    });
                                }
                            },
                            link: {
                                component: LinkLayout,
                                linkCallback: linkFunction
                            }
                        }}
                    />
                </Form.Group>
                <ButtonComponent
                    marginLeft='0'
                    width='8em'
                    marginRight='14px'
                    text='Anuluj'
                    onClick={onCancel}
                />
                <ButtonComponent
                    marginLeft='0'
                    marginRight='0'
                    width='8em'
                    text='Zapisz'
                    type='submit'
                    onClick={inputCheck}
                />
            </Form>
        </>
    );
};

export default NewsEditor;
