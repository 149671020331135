import { User } from 'lib';
import { Param } from 'lib/types';
import _ from 'lodash';

export const USER_LOCAL_STORAGE = 'user';

export type ParamTypes = 'DefaultNewOrderView' | 'DefaultNewOrderViewMobile' | 'DefWindow' | 'DefZamPageCount';

export const NEW_ORDER_VIEW = 'DefaultNewOrderView';
export const NEW_ORDER_VIEW_MOBILE = 'DefaultNewOrderViewMobile';
export const DEF_WINDOW = 'DefWindow';
export const ORD_PAGE_COUNT = 'DefZamPageCount';

const logout = () => {
    sessionStorage.removeItem(USER_LOCAL_STORAGE);
};

const get = (): User | null => {
    const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
    return user === null ? null : (JSON.parse(user) as User);
};

const set = (user: User | null) => {
    if (user === null) {
        logout();
    } else {
        sessionStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(user));
    }
};

const getUser = (): User => {
    const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
    if (!user) {
        throw new Error('Cannot retrieve user from sessionStorage');
    }
    return JSON.parse(user) as User;
};

const getUserParams = () => {
    const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
    if (!user) {
        throw new Error('Cannot retrieve user from sessionStorage');
    }
    return (JSON.parse(user) as User).params as Param[];
};

const getUserParam = (key: ParamTypes) => {
    const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
    if (!user) {
        return null;
    }
    const param = _.filter(((JSON.parse(user) as User).params as Param[]), { ParamName: key });
    return param[0].ParamValue;
};

const updateUserParamsInSessionStorage = (params: Param[]) => {
    const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
    if (!user) {
        throw new Error('Cannot retrieve user from sessionStorage');
    }
    const updatedUser: User = (JSON.parse(user) as User);
    updatedUser.params = params;
    sessionStorage.setItem('user', JSON.stringify(updatedUser));
};

export const userService = {
    get,
    set,
    getUser,
    logout,
    getUserParams,
    getUserParam,
    updateUserParamsInSessionStorage
};
