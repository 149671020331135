import React, { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import PromotionProducts from './PromotionProducts/PromotionProducts';
import PromotionTerms from './PromotionTerms/PromotionTerms';
import PromotionOrders from './PromotionOrders/PromotionOrders';
import ManageTraders from './ManageTraders/ManageTraders';
import TraderRegistration from './TraderRegistration/TraderRegistration';
import RooferRegistration from './RooferRegistration/RooferRegistration';
import TermsAcceptation from './TermsAcceptation/TermsAcceptation';
import PromotionRegulations from './PromotionRegulations/PromotionRegulations';
import LinkItem from 'components/LinkItem';
import { useMediaQuery } from 'react-responsive';
import { MD, USER_ROLE_MASTER, USER_ROLE_ROOFER, USER_ROLE_TRADER } from 'lib/util';
import { useUser } from 'reducers';

const Promotions = () => {
    const location = useLocation();
    const isDesktop = useMediaQuery({ minWidth: MD });
    const user = useUser();
    const [activeKey, setActiveKey] = useState('accept-terms');
    const history = useHistory();
    const areRulesAccepted = user?.promotionTermsAccept !== '19010101' && user?.promotionalProgramEnroll !== '19010101';

    const notRestrictedPaths = [
        `${process.env.PUBLIC_URL}/promotions/accept-terms`,
        `${process.env.PUBLIC_URL}/promotions/promotion-regulations`
    ];

    useEffect(() => {
        if (!notRestrictedPaths.includes(location.pathname) && !areRulesAccepted) {
            history.replace(`${process.env.PUBLIC_URL}/promotions/accept-terms`);
        } else if (location.pathname === `${process.env.PUBLIC_URL}/promotions`) {
            history.replace(`${process.env.PUBLIC_URL}/promotions/articles`);
        }

        if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions/terms`)) {
            setActiveKey('terms');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions/orders`)) {
            setActiveKey('orders');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions/register-roofer`)) {
            setActiveKey('register-roofer');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions/register-trader`)) {
            setActiveKey('register-trader');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions/manage-traders`)) {
            setActiveKey('manage-traders');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions/articles`)) {
            setActiveKey('articles');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions/promotion-regulations`)) {
            setActiveKey('promotion-regulations');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions/accept-terms`)) {
            setActiveKey('accept-terms');
        }

        if (areRulesAccepted && activeKey === 'accept-terms') {
            setActiveKey('articles');
        }
    }, [location.pathname]);

    return (
        <>
            {isDesktop && (
                <Navbar className='sub-navbar' expand='lg'>
                    {user?.promotionTermsAccept !== '19010101' && user?.promotionalProgramEnroll !== '19010101' ? (
                        <Nav
                            justify
                            variant='pills'
                            className='flex-grow-1 flex-shrink-1'
                            activeKey={activeKey}
                        >
                            <LinkItem to={`${process.env.PUBLIC_URL}/promotions/articles`} eventKey='articles'>Artykuły promocyjne</LinkItem>
                            <LinkItem to={`${process.env.PUBLIC_URL}/promotions/orders`} eventKey='orders'>Zamówienia na artykuły promocyjne</LinkItem>
                            {user?.role !== USER_ROLE_ROOFER && <LinkItem to={`${process.env.PUBLIC_URL}/promotions/register-roofer`} eventKey='register-roofer'>Rejestruj dekarza</LinkItem>}
                            {user?.role === USER_ROLE_MASTER && <LinkItem to={`${process.env.PUBLIC_URL}/promotions/register-trader`} eventKey='register-trader'>Rejestruj handlowca</LinkItem>}
                            {user?.role === USER_ROLE_MASTER && <LinkItem to={`${process.env.PUBLIC_URL}/promotions/manage-traders`} eventKey='manage-traders'>Administracja handlowcami</LinkItem>}
                            <LinkItem to={`${process.env.PUBLIC_URL}/promotions/terms`} eventKey='terms'>Regulamin</LinkItem>
                            <LinkItem to={`${process.env.PUBLIC_URL}/promotions/promotion-regulations`} eventKey='promotion-regulations'>Zasady działania programu promocyjnego</LinkItem>
                        </Nav>
                    ) : (
                        <Nav
                            justify
                            variant='pills'
                            className='flex-grow-1 flex-shrink-1'
                            activeKey={activeKey}
                        >
                            <LinkItem to={`${process.env.PUBLIC_URL}/promotions/accept-terms`} eventKey='accept-terms'>Akceptacja Regulaminu</LinkItem>
                            <LinkItem to={`${process.env.PUBLIC_URL}/promotions/promotion-regulations`} eventKey='promotion-regulations'>Zasady działania programu promocyjnego</LinkItem>
                        </Nav>
                    )}
                </Navbar>
            )}
            <Switch>
                <Route path={`${process.env.PUBLIC_URL}/promotions/articles`}>
                    <PromotionProducts />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/promotions/terms`}>
                    <PromotionTerms />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/promotions/accept-terms`}>
                    <TermsAcceptation />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/promotions/promotion-regulations`}>
                    <PromotionRegulations />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/promotions/orders/:page`}>
                    <PromotionOrders />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/promotions/orders`}>
                    <PromotionOrders />
                </Route>
                {(user?.role === USER_ROLE_MASTER || user?.role === USER_ROLE_TRADER) &&
                    <Route path={`${process.env.PUBLIC_URL}/promotions/register-roofer`}>
                        <RooferRegistration />
                    </Route>}
                {user?.role === USER_ROLE_MASTER &&
                    <Route path={`${process.env.PUBLIC_URL}/promotions/register-trader`}>
                        <TraderRegistration />
                    </Route>}
                {user?.role === USER_ROLE_MASTER &&
                    <Route path={`${process.env.PUBLIC_URL}/promotions/manage-traders`}>
                        <ManageTraders />
                    </Route>}
                {areRulesAccepted ?
                    <Route>
                        <PromotionProducts />
                    </Route> :
                    <Route>
                        <TermsAcceptation />
                    </Route>}
            </Switch>
        </>
    );
};

export default Promotions;
