import React from 'react';
import { Spinner as Spin } from 'react-bootstrap';
import ButtonComponent from 'components/ButtonComponent';
import './Spinner.scss';

interface SpinnerProps {
    showError?: boolean;
    centered?: boolean;
    toPdf?: boolean;
    errMsg?: string;
    button?: SpinnerButtonType;
}

export interface SpinnerButtonType {
    label: string;
    onClick: () => void;
}

const Spinner = ({ showError = false, centered = true, toPdf = false, errMsg = 'Błąd ładowania.', button }: SpinnerProps) => (
    <>
        {toPdf ? (
            <div id={centered ? 'spinner-centered' : 'spinner'} style={{ margin: 'auto' }}>
                {showError ? <div id='error'>{errMsg}</div> : <Spin animation='border' id='spin' style={{ width: '64px', height: '64px' }} />}
                {button ? <ButtonComponent onClick={button.onClick}>{button.label}</ButtonComponent> : <></>}
            </div>
        ) : (
            <div id={centered ? 'spinner-centered' : 'spinner'}>
                {showError ? <div id='error'>{errMsg}</div> : <Spin animation='border' id='spin' />}
                {button ? <ButtonComponent onClick={button.onClick}>{button.label}</ButtonComponent> : <></>}
            </div>
        )}
    </>
);

export default Spinner;
