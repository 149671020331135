import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { changePassword } from 'lib/communication/userInfo';
import Spinner from 'components/Spinner/Spinner';
import { logout } from 'lib/communication';
import ButtonComponent from 'components/ButtonComponent';

const ChangePassword = () => {
    const [waiting, setWaiting] = useState(false);
    const history = useHistory();
    const [justEntered, wasJustEntered] = useState(true);
    const [submitedWrong, wasSubmitedWrong] = useState(false);

    const [pwdMatch, setPwdMatch] = useState(true);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

    const handleNewPasswordChange = (e: any): void => {
        const updatedPwd = e.target.value;
        setNewPassword(updatedPwd);
        setPwdMatch(updatedPwd === newPasswordRepeat);
        wasJustEntered(false);
    };

    const handleNewPasswordRepeatChange = (e: any): void => {
        const updatedPwdRepeat = e.target.value;
        setNewPasswordRepeat(updatedPwdRepeat);
        setPwdMatch(newPassword === updatedPwdRepeat);
        wasJustEntered(false);
    };

    const handleOldPasswordChange = (e: any): void => {
        const updatedOldPassword = e.target.value;
        setOldPassword(updatedOldPassword);
        wasJustEntered(false);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setWaiting(true);

        try {
            await changePassword(oldPassword, newPassword);
            alert(
                'Zmiana hasła powiodła się, za chwile zostaniesz wylogowany i przeniesiony do strony głównej. Zaloguj się ponownie nowym hasłem'
            );
            logout();
            history.push('/');
        } catch (err) {
            wasSubmitedWrong(true);
        }

        setWaiting(false);
    };

    const pwdNotMatchWarning = !pwdMatch && <p className='Warining-text'>Powtórzone hasło się nie zgadza</p>;

    return (
        <>
            <h2>Proszę utworzyć nowe hasło</h2>
            {waiting && <Spinner />}
            <div className='Login'>
                <Form noValidate validated={justEntered ? !pwdMatch : pwdMatch} onSubmit={onSubmit} className={submitedWrong ? 'Login-body was-validated' : 'Login-body'}>
                    <Form.Group controlId='oldPassword'>
                        <Form.Label>Dotychczasowe hasło</Form.Label>
                        <Form.Control type='password' required onChange={(e) => handleOldPasswordChange(e)} />
                        <Form.Control.Feedback type='invalid'>Podaj poprawne hasło.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='newPassword'>
                        <Form.Label>Nowe hasło</Form.Label>
                        <Form.Control
                            type='password'
                            required
                            value={newPassword}
                            onChange={(e) => handleNewPasswordChange(e)}
                        />
                    </Form.Group>
                    <Form.Group controlId='newPasswordRepeat'>
                        <Form.Label>Powtórz nowe hasło</Form.Label>
                        <Form.Control
                            type='password'
                            required
                            value={newPasswordRepeat}
                            onChange={(e) => handleNewPasswordRepeatChange(e)}
                        />
                    </Form.Group>
                    {pwdNotMatchWarning}
                    <ButtonComponent
                        text='Zmień hasło'
                        type='submit'
                        disabled={!pwdMatch}
                    />
                </Form>
            </div>
        </>
    );
};

export default ChangePassword;
