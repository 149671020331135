import { GutteringSystemDataV2 } from 'lib/types';
import { dataActions } from 'reducers';
import store from 'store';

export const setGutterCreatorFilter = async (filter: { filterName: string; value: string; }) => {
    store.dispatch(dataActions.setGutterCreatorFilter(filter));
};

export const setGuttertoBeUpdated = async (value: boolean) => {
    store.dispatch(dataActions.setGutterToBeUpdated(value));
};

export const setGutterCreatorFilters = async (filters: GutteringSystemDataV2) => {
    store.dispatch(dataActions.setGutterCreatorFilters(filters));
};
