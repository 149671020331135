import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { Breadcrumb } from 'react-bootstrap';
import _ from 'lodash';
import { arrayPathToString, AssortmentSelectionState, BASIC_GROUP, firstNGroups, GROUP_CATEGORY_NAME, GROUP_ORDER, isSpecialGroup, pathToArray } from './AssortmentSelection';
import ProductDescriptionDetails from '../Cards/ProductDescriptionDetails';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import { Product, ProductsGroup } from 'lib/types';
import { getProductsByGroup } from 'lib/communication/orders';
import GroupCard from '../Cards/GroupCard';
import ProductCard from '../Cards/ProductCard';
import Spinner from 'components/Spinner/Spinner';
import './AssortmentSelection.scss';

interface BasicViewProps {
    currentLevel: number;
    selectedPath: string;
    selectedView: string;
    selectedProduct: Product | null;
    setSelectedProduct: (_: Product | null) => void;
    error: boolean;
    setError: (_: boolean) => void;
    setCurrentLevel: (_: number) => void;
    setSelectedPath: (_: string) => void;
    setProduct: (_: Product | null) => void;
    toggleTreeNode: (nodeKey: string) => void;
}

const BasicView = ({
    currentLevel,
    selectedPath,
    selectedProduct,
    selectedView,
    error,
    setSelectedProduct,
    setError,
    setCurrentLevel,
    setSelectedPath,
    setProduct,
    toggleTreeNode
}: BasicViewProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });

    const allProducts = useSelector((state: AppState) => state.data.orders.groupsAndProducts.products);
    const company = useSelector((state: AppState) => state.session.user?.company);
    const groups = useSelector((state: AppState) => state.data.orders.groupsAndProducts.groups);
    const isFetchingGroups = useSelector((state: AppState) => state.data.orders.props.isFetchingGroups);

    const [groupsToShow, setGroupsToShow] = useState([] as ProductsGroup[]);
    const [products, setProducts] = useState([] as Product[]);

    const history = useHistory<AssortmentSelectionState>();

    const expandGroup = useMemo(() => (group: ProductsGroup | undefined) => {
        if (!group) {
            setSelectedPath(BASIC_GROUP);
            toggleTreeNode(BASIC_GROUP);
            setCurrentLevel(0);
        } else {
            if (group.hasProducts) {
                getProductsByGroup(group.path, company).then((result) => {
                    setError(!result);
                });
            }
            setCurrentLevel(group.level - 1);
            setSelectedPath(group.path);
        }
    }, []);

    useEffect(() => {
        let grpath: string | undefined;
        if (history.location.state) {
            const productInHistory = history.location.state.selectedProduct;

            if (productInHistory) {
                setProduct(productInHistory as Product);
                grpath = productInHistory.path.join('\\');
            } else {
                setProduct(null);
                grpath = history.location.state.selectedPath;
            }
        }

        expandGroup(_.find(groups, (g) => g.path === grpath));
    }, [history.location.state, groups]);

    useEffect(() => {
        if (!groups) return;

        const selectedPathArray = pathToArray(selectedPath);
        if (selectedPathArray.length <= 1) {
            setProducts([]);
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const group of groups) {
            if (firstNGroups(selectedPath, group.level) === group.path) {
                const p = _.filter(
                    allProducts,
                    (product) => arrayPathToString(product.path) === selectedPath
                );
                setProducts(p);
                return;
            }
        }
    }, [groups, allProducts, selectedPath]);

    const findGroupsToShow = (): Array<ProductsGroup> => {
        // Filter all paths that have property hasProduct true and path starts with
        const pathsToSearch = _.map(
            _.filter(
                groups,
                (group) => group.hasProducts &&
                    _.every(
                        pathToArray(selectedPath),
                        (path, index) => index === 0 ||
                            (pathToArray(group.path)[index] !== undefined && _.isEqual(pathToArray(group.path)[index], path))
                    )
            ),
            (group) => group.path
        );

        // Filter all groups by level
        const filteredByLevel = _.filter(groups, (group) => group.level - 1 === GROUP_ORDER[currentLevel]);

        const filteredByGroup: ProductsGroup[] = _.filter(filteredByLevel, (group) => _.some(pathsToSearch, (pathToSearch) => firstNGroups(pathToSearch, group.level) === group.path));

        return filteredByGroup;
    };

    useEffect(() => {
        const filteredGroups = findGroupsToShow();
        setGroupsToShow(filteredGroups);
    }, [currentLevel, selectedPath, groups, selectedView]);

    useEffect(() => {
        if (selectedProduct !== null) return;
        const filteredGroups = findGroupsToShow();
        setGroupsToShow(filteredGroups);
    }, [selectedView]);

    return (
        <>
            <div className='AssortmentSelection-BasicView-header'>
                <Breadcrumb>
                    {_.map(pathToArray(selectedPath), (group, index) => (
                        <Breadcrumb.Item
                            onClick={() => {
                                const path = firstNGroups(selectedPath, index + 1);
                                history.push({
                                    pathname: `${process.env.PUBLIC_URL}/new-order`,
                                    state: {
                                        ...history.location.state,
                                        selectedPath: path,
                                        selectedView: 'basic',
                                        selectedProduct: null
                                    }
                                });
                            }}
                            active={index === pathToArray(selectedPath).length - 1 && selectedProduct === null}
                            key={`bredcrumb${index}`}
                        >
                            {index === 0 ?
                                'Asortyment' :
                                _.find(groups, (g) => firstNGroups(selectedPath, index + 1) === g.path)?.description}
                        </Breadcrumb.Item>
                    ))}
                    {selectedProduct !== null && (
                        <Breadcrumb.Item active key={`bredcrumb${selectedProduct.path}`}>
                            {selectedProduct.description}
                        </Breadcrumb.Item>
                    )}
                </Breadcrumb>

                {currentLevel === 0 ? <h3>Asortyment</h3> : <></>}
                {!selectedProduct && !isSpecialGroup(selectedPath) && groupsToShow.length > 0 && (
                    <h3>{GROUP_CATEGORY_NAME[GROUP_ORDER[currentLevel] - 2]}</h3>
                )}
                {selectedProduct === null && (
                    <div className='AssortmentSelection-MainGrid'>
                        {
                            (isFetchingGroups || error) ? <Spinner showError={error} centered={isDesktop} /> : (
                                <>
                                    {(groupsToShow.length > 0) && (
                                        _.map(groupsToShow, (group, index) => (
                                            <GroupCard
                                                key={`${group.description}${index}`}
                                                click={() => history.push({
                                                    pathname: `${process.env.PUBLIC_URL}/new-order`,
                                                    state: {
                                                        ...history.location.state,
                                                        selectedView: 'basic',
                                                        selectedPath: group.path,
                                                        selectedProduct: null
                                                    }
                                                })}
                                                groupName={group.description}
                                                imgSrc={group.image}
                                                imgAlt=''
                                                path={group.path}
                                            />
                                        ))
                                    )}
                                    {(products.length > 0) && (
                                        _.map(products, (product, index) => (
                                            <ProductCard
                                                key={`product${index}`}
                                                click={() => history.push({
                                                    pathname: `${process.env.PUBLIC_URL}/new-order`,
                                                    state: {
                                                        ...history.location.state,
                                                        selectedView: 'basic',
                                                        selectedPath: product.path.join('\\'),
                                                        selectedProduct: product
                                                    }
                                                })}
                                                description={product.description}
                                                imgSrc={product.image}
                                                imgAlt=''
                                                path={product.symKar}
                                            />
                                        ))
                                    )}
                                    {(groupsToShow.length === 0 && products.length === 0) && <div className='AssortmentSelection-BasicView-empty-catalog'>Brak asortymentu</div>}
                                </>
                            )
                        }
                    </div>
                )}
            </div>
            {selectedProduct !== null && (
                <div className='AssortmentSelection-BasicView-ProductDesc'>
                    <ProductDescriptionDetails selectedProduct={selectedProduct} />
                </div>
            )}
        </>
    );
};

export default BasicView;
