import React, { useState } from 'react';
import './ListSelect.scss';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { buildResourceLink } from 'lib/communication';
import { getProductsInfo } from 'lib/communication/orders';
import { FoundProduct, Product } from 'lib/types';
import { Spinner as Spin } from 'react-bootstrap';
import { AssortmentSelectionState } from './AssortmentSelection';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

interface ListSelectProps {
    message: string | null;
    products: FoundProduct[];
    setShowTree?: (val: boolean) => void;
}

const ListSelect = ({ message = null, products, setShowTree }: ListSelectProps) => {
    const history = useHistory<AssortmentSelectionState>();

    const isSearchingProducts = useSelector((state: AppState) => state.data.orders.props.isSearchingProducts);
    const storedProducts = useSelector((state: AppState) => state.data.orders.groupsAndProducts.products);

    const [productBeingFetched, setProductBeingFetched] = useState<string | null>(null);

    const selectProduct = async (prd: FoundProduct) => {
        setProductBeingFetched(prd.symKar);
        let product: Product | undefined = _.find(storedProducts, (p) => p.symKar === prd.symKar);
        if (product === undefined) {
            const prods: { symKar: string, unit: string }[] = [{
                symKar: prd.symKar,
                unit: prd.unit
            }];
            const result = await getProductsInfo(prods, true);

            if (result && result.length === 1) {
                product = result[0];
            }
        }
        setProductBeingFetched(null);
        if (!product) {
            alert('Nie udało się pobrać szczegółów produktu');
        } else if (!_.isEqual(product, history.location.state?.selectedProduct)) {
            if (setShowTree) {
                setShowTree(false);
            }
            history.push({
                pathname: `${process.env.PUBLIC_URL}/new-order`,
                state: {
                    ...history.location.state,
                    selectedPath: product.path.join('\\'),
                    selectedProduct: product
                }
            });
        }
    };

    return (
        <div className='ListSelect-root'>
            {message ? <div className='ListSelect-message'>{message}</div> : isSearchingProducts ? <div className='ListSelect-message'><Spin animation='border' /></div> : products.length === 0 ? <div className='ListSelect-message'>Nie znaleziono żadnych produktów</div> : (
                <ul className='ListSelect-list'>
                    {
                        _.map(products, (prd: FoundProduct, prdIndex: number) => (
                            <li
                                className={`ListSelect-product ${prd.symKar === history.location.state?.selectedProduct?.symKar ? 'ListSelect-product-selected' : ''}`}
                                onClick={() => { selectProduct(prd); }}
                                key={prd.symKar}
                            >
                                <div className='ListSelect-product-image'>
                                    <img src={prd.image ? buildResourceLink(prd.image) : `${process.env.PUBLIC_URL}/images/ikona.jpg`} alt='' />
                                </div>
                                <div className='ListSelect-product-description'>
                                    {prd.description}
                                </div>
                                {productBeingFetched === prd.symKar ? <div><Spin className='no-margin' animation='border' /></div> : ''}
                            </li>
                        ))
                    }
                </ul>
            )}
        </div>
    );
};

export default ListSelect;
